/* before dashbaord screen */

body {
  background: #fff !important;
}


.filter-projects {
  min-width: 260px;
  background: transparent;
  border: 1px solid #ebebeb;
  padding: 0 10px;
  display: flex;
  align-items: center;
  column-gap: 0;
  justify-content: space-between;
  width: 100%;
}

body .filter-projects input[type="text"] {
  background: none;
  padding: 10px 0 10px 10px !important;
  border: none;
  outline: none;
  width: 100%;
  font-family: "Roboto", serif;
  box-sizing: border-box;
}

.project-nav {
  display: flex;
  justify-content: space-between;
}

.filter-icon {
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  cursor: pointer;
  width: 35px;
}

.filter-icon img {
  width: 90%;
}

.filter-projects-wrap {
  display: flex;
  column-gap: 10px;
}

.filter-projects-wrap svg path {
  fill: #333;
}

.ListProjects-wrapper h3 {
  margin: 0;
  font-size: 13px;
  text-transform: capitalize;
  color: #5b5555;
}

.checkIcon {
  background: #5fcc6e;
  width: 12px;
  height: 12px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}

.archivedIcon {
  background: #e6482a;
  box-shadow: 0 0 10px 3px pink;
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}

.progress-number {
  display: block;
  text-align: right;
}

.formMainWrapper {
  padding: 50px 40px;
}

.projectFormMainWrapper h3 {
  font-size: 25px !important;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0;
  text-align: center;
}

.projectFormMainWrapper .formMainWrapper form {
  padding: 29px 0 0;
  margin-top: 30px;
  border-top: 1px solid rgb(227, 232, 239);
}

.projectFormMainWrapper {
  margin: 0;
  border-radius: 8px;
}

.fieldsWrap > div {
  margin: 0;
}

.fieldsWrap .MuiMenu-paper {
  height: 300px;
}

.fieldsWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 80px;
  gap: 20px;
  margin-bottom: 20px;
}

.projectFormMainWrapper .MuiGrid-item {
  padding: 0;
}

.projectFormMainWrapper .formMainWrapper .MuiGrid-item {
  box-sizing: border-box;
}

.submit-btn {
  text-align: center;
  padding: 30px 0 0;
}

body form .MuiInputBase-input {
  /* min-height: auto !important;
  height: auto !important; */
}
body form textarea {
  padding: 8px 16px;
  min-height: auto !important;
  height: auto !important;
}
body .MuiSelect-select {
  padding: 8px 16px;
}
body form .MuiSelect-select {
  padding: 8px 16px;
  min-height: auto !important;
  height: auto !important;
}

body form .MuiInputBase-input,
body form textarea,
body .MuiSelect-select,
body form .MuiSelect-select,
body form .MuiInputBase-formControl,
body .MuiOutlinedInput-root,
body input[type="text"] {
  border-radius: 8px;
}

body input[type="text"],
body input[type="date"],
body input[type="email"],
body input[type="tel"],
textarea {
  color: #000;
}

body input[type="text"]::placeholder,
body input[type="date"]::placeholder,
body input[type="email"]::placeholder,
body input[type="tel"]::placeholder,
textarea::placeholder {
  color: #000;
}

form .MuiFormLabel-root {
  font-size: 14px;
}

.projectFormMainWrapper .MuiCard-root {
  margin: 0;
}

.fieldsWrap > .MuiFormControl-root > .MuiFormControl-root {
  margin: 0 !important;
}

.MuiMenu-paper {
  min-height: max-content;
}

.MuiMenu-paper::-webkit-scrollbar {
  width: 7px;
}

.MuiMenu-paper::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.MuiMenu-paper::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.MuiMenu-paper ul li {
  padding: 10px 20px;
  margin-bottom: 5px;
}

.MuiMenu-paper ul li.Mui-selected,
.MuiMenu-paper ul li:hover {
  background-color: #e9f0f5 !important;
  border-radius: 6px;
}

.MuiMenu-paper ul li:focus,
.MuiMenu-paper ul li:hover {
  background-color: #e9f0f5;
}

.steps .Mui-active {
  color: #0063c6;
}

.projectConfig-Bar .MuiGrid-container {
  padding: 30px 30px !important;
  margin: 0;
}

.projectConfig-Bar {
  margin: 30px 0 0;
}

/* smart-directory-styles */

body .smart-Filter .MuiSelect-select {
  border: none !important;
  outline: none !important;
  border: 2px solid #eee;
}

body .smart-Filter .MuiFormControl-root {
  max-width: 400px;
  margin: 0 0 0 auto;
}

body .smart-Filter .filter-projects {
  min-width: 279px;
  border: none;
  padding: 8px 30px 8px 20px;
  display: flex;
  align-items: center;
  column-gap: 0;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  transition: 0.3s all;
}

body .smart-Filter .filter-projects svg {
  cursor: pointer;
}

body .smart-Filter .filter-projects:hover {
  border-color: black;
}

/* responsive */

@media (max-width: 1024px) {
  .smart-Filter .MuiGrid-root {
    width: 100%;
    max-width: 100%;
    flex-basis: unset;
  }

  .smart-Filter {
    flex-direction: column;
    row-gap: 20px;
    padding: 30px 20px;
    max-width: 600px;
    margin: 0 auto;
  }

  body .smart-Filter .filter-projects {
    min-width: 100%;
  }

  body .smart-Filter .filter-projects-wrap {
    width: 100%;
  }

  body .smart-Filter .MuiFormControl-root {
    max-width: 100%;
    margin: 0;
  }
}

@media (max-width: 991px) {
  .steps .MuiStep-horizontal {
    padding: 10px 10px 78px 10px;
    position: relative;
  }

  .steps .MuiStepper-root {
    flex-direction: column;
    align-items: center;
  }

  .steps .MuiStepper-root > div:last-child {
    padding: 10px;
  }

  .steps .MuiStepper-root > div:last-child:after {
    display: none;
  }

  .steps .MuiStep-horizontal:after {
    width: 1px;
    height: 73px;
    bottom: 0;
    content: "";
    background: #0063c6;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
  }

  .steps .MuiStepConnector-line {
    display: none;
  }

  .projectConfig-Bar .MuiCardHeader-content .MuiTypography-root {
    font-size: 23px !important;
  }
}

@media (max-width: 900px) {
  .projectConfig-Bar .MuiGrid-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
  }

  .projectConfig-Bar .MuiGrid-container .MuiGrid-item {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
  }

  .projectConfig-Bar .MuiGrid-item .MuiBox-root,
  .projectConfig-Bar .MuiCardHeader-root {
    justify-content: center;
  }

  .MuiCardHeader-content {
    width: auto;
    flex: unset;
  }
}
@media (max-width: 767px) {
  .fieldsWrap > div {
    padding-bottom: 23px;
  }

  .fieldsWrap {
    flex-direction: column;
    height: auto;
    gap: 0;
    margin-bottom: 0;
  }

  .formMainWrapper {
    padding: 40px 30px;
  }

  body form .MuiInputBase-input,
  body form textarea,
  body form .MuiSelect-select {
    padding: 15px 20px;
  }

  form .MuiFormLabel-root {
    line-height: 19px;
  }

  form .MuiFormHelperText-filled,
  .MuiFormHelperText-root {
    bottom: -4px;
    font-size: 11px;
  }

  .projectConfig-Bar .MuiGrid-container {
    padding: 25px !important;
  }
}

@media (max-width: 479px) {
  .projectConfig-Bar .MuiCardHeader-content .MuiTypography-root {
    font-size: 17px !important;
    line-height: 23px;
  }
}
