
.smartdirectoryform .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
  display: none;
  padding: 0 !important
}

.smartdirectoryform .MuiFormLabel-root {
  display: none
}

.smartdirectoryform .MuiInputBase-input:not([type='file']) {
  padding: 0 !important
}

.smartdirectoryform .MuiInputBase-input[type='file'] {
  padding: 8px 2px!important
}

.white-cross-icon svg path {
  stroke: #fff
}

.smartdirectoryform .MuiMenu-paper {
  border: 1px solid #D5D7DA;
  top: 27px;
  margin-top: 2px;
  box-shadow: 0 5px 20px #d5d7dac7;
}

.smartdirectoryform .MuiMenu-paper ul li {
  padding: 7px 10px;
  margin-bottom: 5px;
  font-family: Inter !important;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 20px !important;
  border-radius: 3px
}

body .smartdirectoryform .MuiOutlinedInput-root input {
  color: #717680;
  font-family: 'Inter' !important;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 20px !important;
}