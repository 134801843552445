.dashboardPage {
  /* height: 100vh; */
  overflow-y: auto;
  background: linear-gradient(to bottom, #055e99, #0288e1 90%) fixed !important;
}

/* dashboard styles */
.sidebar svg,
.sidebar ul span {
  color: #7fb6e8;
  text-transform: uppercase;
  display: block;
}

.sidebar svg {
  /* margin-right: 12px; */
}

.sidebar ul span {
  font-size: 10px;
  margin: 0;
}

.sidebar ul a p,
.sidebar ul a.Mui-selected h4 {
  font-weight: 500;
  font-size: 14px;
}

.sidebar ul a.Mui-selected {
  background: white !important;
}
.sidebar ul a.Mui-selected:hover {
  background: white !important;
}

.sidebar ul a:hover {
  background: rgb(7 30 60 / 14%) !important;
  color: #fff;
}

.sidebar ul a:hover p {
  color: #fff;
}

.sidebar ul a.Mui-selected h4,
.sidebar ul a.Mui-selected svg {
  color: #143e7c;
}

nav .MuiDrawer-paper {
  background: transparent !important;
}

.dashboardPage header {
  width: 230px;
  left: 0;
  padding: 0;
}

.dashboardPage main {
  margin: 20px 0 0;
  background: #f5f5f5;
  min-height: 100vh;
  padding: 0;
  border-radius: 24px 0 0 0;
  box-shadow: -3px 0px 10px #00000045;
}

.admin-header.active {
  padding-left: 20px;
}

.admin-header.active span {
  display: none;
}

.active .sidebar {
  width: 80px;
}

.active .sidebar ul a,
.active .sidebar ul a.Mui-selected {
  padding: 0;
  justify-content: center;
  background: transparent !important;
}

.active .sidebar ul a button div {
  border-radius: 99px;
}

.active .sidebar ul a.Mui-selected button div,
.active .sidebar ul a button div:hover {
  background: #fff;
}

.borderBottom {
  border-bottom: 1px solid #c6d3de;
}

.borderTop {
  border-top: 1px solid #c6d3de;
}

.topBarBtn {
  max-height: 40px;
}

.blackBtn {
  background: #000 !important;
  border-radius: 6px;
  border-color: #000;
  color: #fff;
  min-width: 138px;
  width: auto;
  padding: 8px 14px;
  box-shadow: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 23px;
}

.blackBtn .MuiButton-iconSizeLarge {
  margin-right: 6px;
  margin-left: 0;
}

.blackBtn .MuiButton-iconSizeLarge svg {
  font-size: 17px;
}

table td button.blackBtn {
  min-width: 65px;
  padding: 6px 9px;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 19px;
}

.blackBtn:disabled {
  background-color: dimgrey !important;
  color: #000;
  opacity: 0.5;
}

.redBtn {
  background: #ff5630 !important;
  border-radius: 8px;
  border-color: #ff5630;
  color: #fff;
  min-width: 138px;
  width: auto;
  padding: 8px 14px;
  box-shadow: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 23px;
}

table td button.redBtn {
  min-width: 65px;
  padding: 6px 9px;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 19px;
}

.blackBtn:hover {
  background: #143e7c !important;
}

.sidebar-prf .popup {
  width: 100%;
  z-index: 1400;
  border-radius: 12px;
  left: 50% !important;
  transform: translate(-50%, -61px) !important;
  bottom: 200px;
}

.sidebar-prf .popup nav p {
  color: #10294e;
  text-wrap: nowrap;
}

.sidebar-prf .popup .MuiListItemIcon-root {
  min-width: 31px;
}

.sidebar-prf .popup svg {
  color: #10294e;
}

.sidebar-prf {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translatex(-50%);
  z-index: 1400;
  width: calc(100% - 20px);
}

.sidebar-prf .MuiChip-root {
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 12px 12px 0 0;
  padding: 10px;
  min-height: 60px;
  justify-content: flex-start;
  background-color: #1b90de;
  box-shadow: 0 8px 30px #0000001e;
  border: 1px solid #ffffff17;
}

body .popup .MuiList-root {
  max-width: 100% !important;
  min-width: 100% !important;
}

.ps__rail-x,
.ps__rail-y {
  display: none !important;
}

.project_header_wrap .popup {
  right: 22px !important;
  transform: none !important;
  left: auto !important;
  top: 75px !important;
  max-width: 250px;
  width: 100%;
}

/* table tbody tr:hover, */
.analytic-tb table tr:hover,
.my-package-tb tr:not(:first-child):hover {
  background: #d7e3eb !important;
}

.select input,
.search input {
  padding: 12px 10px !important;
}

.search svg path,
.select svg path,
.newStyleField svg path {
  color: #10294e;
}

/* search-field space*/

body .spaceField input[type="text"] {
  padding: 13px 14px 13px 0 !important;
}

.Projectheader {
  display: flex;
  justify-content: space-between;
  max-width: 1488px;
  margin: 0 auto;
  width: 100%;
}

.project_header_wrap {
  text-align: center;
}

.popup nav .MuiButtonBase-root:hover {
  background: rgb(7 30 60 / 10%);
}

.project_target_link:hover svg {
  transform: translateX(10px);
}

.project_target_link:hover {
  background: #ccd9e2 !important;
}

.bgBlueOverlay .MuiModal-backdrop {
  background-color: #67a2e19e;
  backdrop-filter: blur(10px);
}

.quick-action-links:hover {
  background: #f8fafb !important;
  box-shadow: 0 7px 10px #00000014;
}

.quick-action-links:hover .arrow {
  transform: translateX(10px);
}

.direct-card button:active {
  background: #fff !important;
}

.direct-card button:hover svg {
  transform: translateX(5px);
}

.direct-card a {
  text-decoration: none;
  margin-top: 0;
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.direct-card a:hover {
  text-decoration: underline;
}

.direct-card:hover {
  box-shadow: 0 10px 10px #0000001c;
}

.direct-card .MuiCardContent-root {
  padding-top: 0;
}

.direct-card .MuiCardHeader-content span:first-child {
  font-size: 18px;
  font-weight: bold;
}

.direct-card .MuiCardHeader-content span:last-child {
  /* font-size: 14px; */
  color: #000;
}

.direct-card .MuiCardHeader-avatar {
  width: 80px;
  height: 60px;
  background: #eee !important;
  padding: 5px;
  border-radius: 8px;
  margin-right: 10px !important;
}

.direct-card {
  border-color: #e9eaeb;
}

.direct-card .MuiCardHeader-avatar {
  background: #f5f5f5 !important;
}

.direct-card .MuiCardHeader-root {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px 20px 0;
}

.direct-card .MuiCardHeader-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: transparent !important;
}

.monthSelect input[type="text"] {
  padding: 10px 0 10px 15px !important;
  height: 40px;
  min-width: 100px;
}

.addProjectModal .MuiDialog-paper {
  max-width: 80%;
  width: 80%;
  margin: 0;
  padding: 0;
  max-height: 100%;
  overflow: unset;
  border-radius: 12px;
}

.addProjectModal .MuiStepper-root {
  padding: 27px 70px 20px;
  background: #eff2f6;
  border-radius: 12px;
}

.modalContent form .MuiPaper-root:first-child .MuiGrid-item:first-child {
  overflow: visible;
}

.modalContent form .MuiPaper-root:first-child .MuiGrid-item:first-child {
  max-height: calc(100vh - 260px);
}

.modalContent form .MuiPaper-root:first-child {
  margin: 0;
  border-radius: 0;
}

.modalContent
  form
  .MuiPaper-root:first-child
  .MuiGrid-item:first-child::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.modalContent
  form
  .MuiPaper-root:first-child
  .MuiGrid-item:first-child::-webkit-scrollbar-track {
  background: #eff2f6;
}

/* Handle */
.modalContent
  form
  .MuiPaper-root:first-child
  .MuiGrid-item:first-child::-webkit-scrollbar-thumb {
  background: #ccd9e2;
  border-radius: 20px;
}

/* Handle on hover */
.modalContent
  form
  .MuiPaper-root:first-child
  .MuiGrid-item:first-child::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.direct-card .MuiAvatar-root {
  width: 50px;
  height: 50px;
}

.direct-card button svg {
  margin-left: -10px;
}

/* Handle the case where the table only has one cell */
body table tbody td:only-child {
  border-radius: 12px !important; /* Adjust radius value as needed */
}

body table tbody tr th:first-child {
  border-radius: 12px 0 0 12px;
  font-weight: bold;
}

body table tbody th:last-child {
  border-radius: 0 12px 12px 0 !important;
}

/* Handle the case where the table only has one cell */
body table tbody th:only-child {
  border-radius: 12px !important; /* Adjust radius value as needed */
}

.single-table-row {
  border-radius: 12px 12px 12px 12px !important;
}

table tr td.center,
table tr th.center {
  text-align: center !important;
}

table tr td.right,
table tr th.right {
  text-align: right !important;
}

.localContent-tb table th p {
  font-size: 12px;
}
.dwn-btn button {
  padding: 0;
}

.my-package-tb tr:first-child {
  background: transparent;
  margin: 0 !important;
}

table tr td .MuiIconButton-root {
  padding: 6px;
}

/*tabs*/

.MuiToggleButtonGroup-root .MuiToggleButton-root {
  border: 1px solid rgb(208, 215, 222) !important;
  color: #000;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 16px;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:hover {
  background: #e9f0f5 !important;
}

.listItems ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItems {
  padding: 24px 20px 20px;
}

.listItems ul li {
  margin-bottom: 18px;
  padding-left: 37px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  font-weight: 500;
}

.popuptabs .MuiTab-root {
  border: 1px solid transparent !important;
  border-radius: 5px !important;
  color: #000;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 16px;
  min-height: auto;
}

.popuptabs .MuiTabs-indicator {
  display: none;
}

.popuptabs .MuiTabs-flexContainer {
  border: none;
}

.popuptabs .MuiTab-root:not(.Mui-selected):hover {
  background: #e9f0f5 !important;
}

.employpop table {
  margin-top: 10px;
}

.employpop .MuiIconButton-root {
  right: 8px !important;
}

.topTable > div {
  background: #e9f0f5;
  margin-top: 5px !important;
  border-radius: 12px !important;
  transition: 0.3s all;
  padding: 10px 15px;
}

.topTable {
  max-width: 400px;
}

.topTable > div:hover {
  background: #d7e3eb !important;
}

.topTable .avatar {
  max-width: max-content;
}

body .MuiMenu-list {
  padding: 10px;
}

body .MuiDateCalendar-root {
  padding: 10px 0;
}

body .MuiPickersMonth-monthButton.Mui-selected {
  background: #000 !important;
}

.projectIcon path {
  fill: transparent !important;
}

body tr td.fullBorderRad:first-child {
  border-radius: 12px !important;
}

body .projectTextField input[type="text"],
body .transpField input[type="text"] {
  padding: 10px 15px 10px 15px !important;
  background: transparent;
}

body .projectTextField .MuiOutlinedInput-root,
body .transpField .MuiOutlinedInput-root {
  background: transparent;
}

.statusField fieldset {
  top: 0;
}

.statusField {
  margin: 0;
}

.MuiToggleButtonGroup-root,
.popuptabs .MuiTabs-flexContainer {
  /* border: 1px solid rgb(208, 215, 222); */
  width: max-content;
  border-radius: 5px;
}

.MuiToggleButton-root,
.popuptabs .MuiTabs-flexContainer {
  border: 1px solid rgb(208, 215, 222) !important;
  width: max-content;
  border-radius: 5px;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:not(:last-child) {
  border-right: 1px solid rgb(208, 215, 222);
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:hover {
  background: #eaedf0 !important;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected,
.popuptabs .Mui-selected {
  background: #f2f2f2 !important;
  color: #000;
  font-weight: 500;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:first-child,
.popuptabs .MuiTab-root:first-child {
  border-radius: 5px 0 0 5px !important;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:last-child,
.popuptabs .MuiTab-root:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.borderBottom .MuiTypography-h1 {
  font-size: 24px !important;
}

.borderBottom .MuiTypography-h1 {
  font-size: 24px !important;
}

.newStyleField > div > .MuiOutlinedInput-root {
  height: 40px !important;
}

.search fieldset,
.select fieldset,
.newStyleField fieldset {
  border-radius: 6px;
  padding-right: 6px;
  border: 1px solid rgb(208, 215, 222) !important;
  padding-left: 10px;
}

body .select input {
  padding: 8px 6px 8px 0 !important;
  font-size: 14px;
  color: #000 !important;
  background: white;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  height: 10px !important;
}
body .search input {
  padding: 8px 6px 8px 0 !important;
  font-size: 14px;
  color: #000 !important;
  background: rgb(246, 248, 250);
  font-weight: 400;
  font-family: "Inter", sans-serif;
  height: 10px !important;
}

body .select input::placeholder,
body .search input::placeholder {
  color: #000 !important;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

body .search .MuiOutlinedInput-root {
  height: 40px;
  background: rgb(246, 248, 250);
}

body .select .MuiOutlinedInput-root {
  height: 40px;
  background: white;
}

body .MuiAutocomplete-root {
  background: white;
}

.select,
.search {
  min-width: 300px;
}

.tableInput {
  min-width: 120px !important;
  max-width: 300px !important;
  margin: 0px;
}

body .tableInput input[type="text"] {
  padding: 5px 7px 5px 7px !important;
  background: transparent;
}

.arrowLink:hover svg {
  transform: translatex(3px);
}
.arrowLink:hover a {
  background: #c6d3de;
}

.grey-outline-btn {
  background: #fff !important;
  border: 1px solid rgb(208, 215, 222);
  color: #656d76;
}

.grey-outline-btn:hover {
  background: #f2f2f2 !important;
  color: #000;
}

td.fullBorderRad {
  height: 49px;
}

td.fullBorderRad p {
  font-weight: 400 !important;
  font-size: 14px;
}

.progress {
  width: 100%;
  background: #e8eff4;
  display: flex;
  border-radius: 30px;
  height: 16px;
}

.progress .progress-bar {
  border-radius: 30px;
}

.logos-group .MuiAvatarGroup-root {
  justify-content: left;
}

.sidebarmain .MuiDrawer-paper {
  padding-top: 95px;
  transition: 0.3s all;
}
