@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
html,
body {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  background: #fff;
  font-family: 'Montserrat';
}

html {
  scroll-behavior: smooth;
}

.container-new {
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul li {
  font-family: "Montserrat", sans-serif;
}
/* global styles */
.dark-blue-bg {
  background: linear-gradient(to right, #0055c6 0%, #0089de 40%, #01ccfc 100%);
}

.footerbg{
  background: url('../../../assets/images/home/footer-bg.png') no-repeat left center;
  background-size: cover;
}

.light-blue-bg {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 1%,
    rgb(165, 218, 255) 100%
  );
}

/* Header Navigation  */

header {
  padding: 0 15px;
}

.header-default {
  /* position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; */
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 5;
}

.header-sticky {
  /* position: sticky !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; */
  background-color: white;
  transition: all 0.3s ease-in-out;
  background: rgb(255, 255, 255);
  box-shadow: 0 7px 25px 0 #e7eef1;
}

.feature-inner-wrapper > div {
  padding-top: 200px;
  margin-top: -200px;
}

.hamburger-menu {
  display: none;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navigation {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-logo img {
  width: 100%;
}

.site-logo {
  display: inline-block;
  max-width: 210px;
}

.navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation ul li > span,.navigation ul li > a ,
.navigation ul.dropdown-content li a,.navigation ul.dropdown-content li a h5
.mob-nav ul li a {
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}

/* .navigation ul li > span , .navigation ul li > a{
  color: #fff;
} */

.navigation ul > li > span, .navigation ul > li > a{
  color: #fff;
}

.sub-header .navigation ul > li > span, .sub-header .navigation ul > li > a{
  color: #000
}

.navigation ul > li > ul li a{
  color: #000
 }

.navigation ul.dropdown-content li a {
  font-weight: 500;
}

.navigation ul li span:hover,
.navigation ul li ul li:hover a {
  color: #000;
}

.navigation ul li > span:hover {
  color: #fff;
}

.navigation li ul li a,
.mob-nav ul li ul li a {
  font-weight: 500;
}

.nav-login {
  display: flex;
  gap: 0.6rem;
  align-items: center;
}

.mob-nav {
  padding: 0 0 24px 16px;
}

.mob-nav ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.mob-nav ul li:not(:last-child) {
  margin-bottom: 16px;
}

.navigation > ul > li:not(:last-child) span {
  position: relative;
}

.navigation
  > ul
  > li:not(:last-child):hover
  > a:after
  .navigation
  > ul
  > li:not(:last-child):hover
  span:after {
  transform: scale(1);
}

.navigation > ul > li.nav-cta {
  margin-left: 40px;
}

.navigation > ul > li {
  position: relative;
  /* margin-left: 30px; */
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  font-family: 'Montserrat';
}

.navigation > ul > li:hover{
  background: #CDE1F4;
  }
  
  .navigation > ul > li:hover span{
    color: black
  }
  
  .navigation > ul > li:last-child:hover{
   background: transparent
  }
.navigation ul.dropdown-content {
  display: none;
  position: absolute;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.1);
  top: 59px;
  min-width: 200px;
  z-index: 5;
  left: -50px;
  opacity: 0;
  border: 1px solid #d7d7d7;
}

.navigation ul.dropdown-content li a {
  padding: 8px 10px;
  border-radius: 4px;
  display: block;
  position: relative;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.navigation ul li:hover > ul {
  display: block;
  opacity: 1;
}

.mob-nav-dropdown {
  display: block;
}

.dropdown-link {
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.dropdown-link svg {
  margin-left: 5px;
}

.dropdown-link:hover {
  color: #0063C6;
}

.dropdown-link.svg-rotate svg {
  transform: rotate(180deg);
  transform: 0.3s all ease-in-out;
}

.mob-nav ul li ul {
  gap: 0;
  padding: 10px 7px 7px;
}

.mob-nav ul li ul li a {
  padding: 10px;
  display: block;
  border-bottom: 1px solid #2121212b;
  font-family: 'montserrat';
  color: #000;
  text-decoration: none;
}

header ul li a.nav-login {
  font-size: 14px;
  transition: 0.3s all;
  text-decoration: none;
  color: #008ce6;
  font-weight: 500;
}

.navigation .demo-btn > a,
.mob-nav ul li.demo-btn > a {
  background: rgb(0, 99, 198);
  padding: 12px 20px;
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  transition: 0.3s all;
  text-decoration: none;
  font-weight: 700;
}

.navigation .demo-btn > a:hover {
  background: #0099fa;
}

header ul li a.nav-login svg path {
  fill:rgb(0, 99, 198);
}

header ul li a.nav-login:hover svg path {
  fill: rgb(0, 99, 198);
}

header ul li a.nav-login svg {
  width: 29px;
  height: 31px;
}

/* mob-nav */
.mob-nav ul {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.mob-nav ul li a {
  transition: 0.3s all;
}

.dropdown-link span {
  display: flex;
  align-items: center;
}

.mob-nav .demo-btn {
  display: flex;
  gap: 12px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  border-top: 1px solid grey;
  padding: 30px 0 20px;
  justify-content: center;
  margin-top: 20px;
}

.mob-nav .demo-btn > a {
  justify-content: center;
}

.mob-nav .demo-btn > a:hover{
  background: #054F81;
}


.mob-nav .demo-btn a.nav-login {
  background: transparent;
  color: rgb(0, 99, 198);
  width: auto;
  justify-content: flex-start;
  padding: 10px 0;
}

.mob-nav .demo-btn a.nav-login:hover {
  color: #00aaff;
}

.mob-nav .demo-btn a.nav-login:hover svg path {
  fill: #00aaff;
}

/* Hero Section  */

.hero-new {
  position: relative;
  padding-bottom: 5%;
  margin-top: -13%;
  background: #fff;
}

.hero-new:after {
  background: url(../../../assets/images/home/bannerbg.png) no-repeat;
  background-position: bottom;
  background-size: cover;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% + 5vw);
  height: 100%;
}

.hero-main {
  padding: 261px  16px 50px;
  display: flex;
  align-items: center;
}

.hero-content {
  width: calc(100% - 661px);
}

.hero-img {
  max-width: 721px;
  position: relative;
}

.hero-img img {
  width: 100%;
}

.widget-1 {
  position: absolute;
  animation: 10s slideY-new linear infinite;
  width: 220px;
  bottom: 32%;
  right: 26%;
}

.widget-2 {
  position: absolute;
  top: 3%;
  animation: 10s slideY-new linear infinite;
  width: 226px;
  right: 26%;
}

.hero-content h1 {
  font-weight: bold;
  font-size: 56px;
  line-height: 1.2em;
  color: #fff;
  padding: 0;
  margin: 0;
}

.hero-content span {
  color: #fff;
}

.hero-content p {
  color: #fff;
  padding: 0;
  margin: 0;
  line-height: 1.6em;
  margin: 19px 0 36px;
  max-width: 600px;
}

.hero-btns a {
  border: 1px solid #fff;
  border-radius: 9px;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  margin-top: 18px;
  padding: 16px 28px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  color: #fff;
  font-family: "Montserrat";
}

.hero-btns .discover {
  color: white;
  background-color: #054f81;
  border: 1px solid #054f81;
  border-radius: 34px;
  min-height:54px;
  padding: 3px 3px 3px 28px;
}

.hero-btns .discover img{
  width: 40px;
  height: 40px
 }

.hero-btns .discover:hover {
  background-color: rgb(0, 99, 198);
  border-color: rgb(0, 99, 198);
}

.hero-btns .request {
  background-color: transparent;
}

.hero-btns .request:hover {
  background-color: #fff;
  color: #054f81;
}

.hero-btns div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -10px;
  width: calc(100% + 20px);
}

.hero-btns div a {
  margin: 0 10px 10px 10px;
}

/* Logo Slider  */
.slider-logo {
  padding: 0 0 30px;
}

.slider-logo-wrap {
  position: relative;
  background: #fff;
}

.slider-logo h3 {
  color: #b3b3b3;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: 1.4;
  margin: 40px 0 15px;
  text-align: center;
  text-transform: uppercase;
}

.slider-logo-wrap:after,
.slider-logo-wrap:before {
  width: 108px;
  height: 100%;
  right: 0;
  background: linear-gradient(
    -90deg,
    rgb(255 255 255) 0%,
    #ffffff 12.72%,
    #ffffffc2 87.58%,
    rgb(217 217 217 / 0%) 100%
  );
  position: absolute;
  content: "";
  top: -1px;
  z-index: 1;
}

.slider-logo-wrap:before {
  left: 0;
  right: auto;
  transform: rotate(-180deg);
}

.slider-logo img {
  max-width: 50%;
  margin: 0 auto;
}

/* About Section */
.about-new {
  padding: 50px 0 140px
}

.about-new.bg-overlay:after {
  width: 649px;
}

.about-new-section .about-sec-txt {
  text-align: center;
  font-size: 18px;
  line-height: 1.6em;
  margin: 0;
  color: #000;
  text-align: center;
}

.about-new-section .about-tagline {
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2em;
  text-align: center;
  color: #000;
  margin: 1rem 0 1rem 0;
  text-align: center;
}

.about-new-section .about-title {
  color: #000;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 0;
  text-align: center;
}

.about-cards-section {
  /* display: flex;
  justify-content: space-between; */
  padding-top: 42px;
  gap: 30px;
}

.about-card h3 {
  font-size: 22px;
  color: #000;
  letter-spacing: normal;
  font-weight: bold;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.about-card h4{
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.about-card p {
  font-size: 14px;
  color: #000;
  padding: 0;
  margin: 0;
}

.about-card {
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;
  padding: 40px 40px 35px;
  width: calc(33.33% - 20px);
border: 1px solid #4341410f;
}

.about-card img {
  width: 100%;
}

.slick-dots li button:before {
  color: #c3cdd3 !important;
  opacity: 1 !important;
}

.slick-dots li {
  width: 10px !important;
}

.slick-dots li button:before {
  font-size: 11px !important;
}

.slick-dots li.slick-active button:before {
  color: #05214b !important;
}

.about-cards-section .about-card:hover {
  box-shadow: 0 10px 15px #0000001a;
}

.about-cards-section .about-card:hover img {
  transform: scale(1.1);
  transition: 0.3s all;
}


.card-img {
  height: 184px;
  margin: 0 auto 30px;
}
/* .about-cards-section .about-card img {
  transition: 0.3s all;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
} */

.about-cards-section .about-card {
  transition: 0.3s all;
}

/* Feature Section  */
.feature-tab-section {
  padding: 75px 16px;
}

.feature-title {
  text-align: center;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.feature-tagline {
  letter-spacing: 0.08em;
  font-weight: bold;
  font-size: 35px;
  line-height: 1.2em;
  text-align: center;
  color: #0063c6;
  margin: 1rem 0 1rem 0;
}

.feature-txt-new {
  font-size: 16px;
  line-height: 1.6em;
  text-align: center;
  color: #0063c6;
  max-width: 872px;
  margin: 0 auto;
}

.featrure-tabs-buttons {
  grid-gap: 2.5rem;
  display: grid;
  gap: 2.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 2.5rem 0 3.5rem;
}

.featrure-tabs-buttons button {
  color: #2f77d3;
  border-radius: 9px;
  font-family: "Montserrat";
  outline: none;
  border: none;
  padding: 16px 28px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s;
  border: 1px solid #0063c6;
  background: #ffffff73;
}

.featrure-tabs-buttons button:hover {
  transform: scale(1.04);
}

.featrure-tabs-buttons .active-tab,
.featrure-tabs-buttons button:hover {
  color: #ffffff;
  background-color: #2f77d3;
}

.inner-feature-container {
  border-radius: 28px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  padding: 61px 30px 23px;
}

.reportingSection .featrure-tabs-buttons button:not(.active-tab) {
  background: #88cbfc26;
}

.reportingSection .featrure-tabs-buttons button.active-tab,
.reportingSection .featrure-tabs-buttons button:not(.active-tab):hover {
  color: #ffffff;
  background-color: #2f77d3;
}

/* Co-ordinate Section  */
.feature-inner-wrapper {
  max-width: 1050px;
  margin: 0 auto;
}

.cooridnate-social {
  display: flex;
  justify-content: space-between;
}

.cooridnate-img {
  max-width: 480px;
  padding-left: 40px;
  margin-top: -29px;
}

.cooridnate-img img {
  position: relative;
  width: 100%;
  transition: 0.3s all;
}

.cooridnate-img:hover img {
  transform: scale(1.05);
}

.cooridnate-social-content {
  width: calc(100% - 480px);
}

.cooridnate-social-content h4 {
  color: #0063c6;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 0;
}

.cooridnate-social-content h3 {
  letter-spacing: 0.08em;
  font-weight: bold;
  font-size: 31px;
  line-height: 1.2em;
  color: #0063c6;
  margin: 1.5rem 0 2rem 0;
  max-width: 490px;
}

.cooridnate-social-content ul {
  padding: 0;
  margin: 0 0 0 1rem;
  color: #0063c6;
}

.cooridnate-social-content ul li {
  font-size: 16px;
  line-height: 1.6em;
  color: #0063c6;
}

.cooridnate-social-content a {
  border: 1px solid #0063c6;
  border-radius: 9px;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  margin-top: 40px;
  padding: 16px 28px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  font-family: "Montserrat";
}

.cooridnate-social-content .discover {
  color: white;
  background-color: #0063c6;
  margin-right: 1.5rem;
  border: 1px solid #0063c6;
}

.cooridnate-social-content .discover:hover {
  background-color: #18509e;
}

.cooridnate-social-content .request {
  color: #0063c6;
  background-color: transparent;
}

.cooridnate-social-content .request:hover {
  background-color: #0063c6;
  color: white;
}

/* Contact Form Section  */

.contact-section {
  align-items: center;
  display: flex;
  padding: 90px 20px 90px;
  max-width: 1170px;
}

.contact-slider {
  width: calc(100% - 500px);
  padding-left: 50px;
}


.contact-slider-img {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.contact-slider-img img {
  max-width: 350px;
  width: 100%;
}

.contact-form-new h5 {
  letter-spacing: 0.08em;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.2em;
  color: #0063c6;
  margin: 20px 0 40px;
}

.contact-form-new h4 {
  color: #000;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin: 0 0 7px;
}

.form-label-new {
  font-weight: bold;
  color: #000;
  display: inline-block;
  padding: 0 0 7px 9px;
  font-family: "Montserrat";
}

.contact-form {
  margin-bottom: 0.5rem;
}

.form-control-new {
  margin-bottom: 20px;
}

.contact-form-new input[type='text'], .contact-form-new input[type='number'], .contact-form-new  input[type='email'],
.contact-form-new textarea {
  color: #000 ;
  padding: 16px 17px ;
  border: none;
  background-color: #F6F8FA;
  width: 100%;
  border-radius: 10px;
  font-family: "Montserrat";
  border: 1px solid #DFE5ED;
  outline: transparent;
  outline: none;
}

.contact-form-new textarea {
  resize: none;
}

.contact-form-new input:focus,
.contact-form-new textarea:focus,
.contact-form-new input:hover,
.contact-form-new textarea:hover {
  border: 1px solid #000;
  outline: #000;
  background-color: #ffffff73;
}

.contact-form-new input::placeholder,
.contact-form-new textarea::placeholder {
  color: #000 !important;
  font-weight: 400 !important;
  font-family: "Montserrat";
}

p.danger{
  margin-top: 6px;
  font-size: 14px;
}

.contact-form-btn {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  border-radius: 12px;
  display: inline-block;
  margin-top: 1.5rem;
  padding: 17px 50px;
  background-color: #0063c6;
  transition: 0.25s all ease-in-out;
  font-family: "Montserrat";
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
}

.contact-form-btn:hover {
  background-color: #18509e;
  transition: 0.25s all ease-in-out;
}

.contact-slider-content {
  text-align: center;
}

.contact-slider-content h4 {
  color: #0063c6;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 0;
}

.contact-slider-content h3 {
  letter-spacing: 0.08em;
  font-weight: bold;
  font-size: 29px;
  line-height: 1.2em;
  color: #0063c6;
  max-width: 467px;
  margin: 1.5rem auto;
}

.slider-content-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-slider-content ul {
  color: #0063c6;
  margin: 0 0 0 1rem;
  padding: 0;
  text-align: left;
}

.contact-slider-content ul li {
  color: #0063c6;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.contact-slider-content a {
  border: 1px solid #0063c6;
  border-radius: 9px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  padding: 13px 15px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.contact-slider-content .discover {
  color: white;
  background-color: #0063c6;
  margin-right: 20px;
  border: 1px solid #0063c6;
}

.contact-slider-content .discover:hover {
  background-color: #18509e;
}

.contact-slider-content .request {
  color: #0063c6;
  background-color: transparent;
}

.contact-slider-content .request:hover {
  background-color: #0063c6;
  color: white;
}

/* CTA Section  */
.cta-new-section {
  text-align: center;
  padding: 40px;
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 30px;
}

.cta-new-section h4 {
  color: #1479D7;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 15px
}

.cta-new-section h3 {
  font-weight: bold;
  font-size: 30px;
  line-height: 1.2em;
  text-align: center;
  color: #000;
  margin: 10px 0;
}

.border-btn {
  border: 1px solid #0063c6;
  border-radius: 9px;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  margin-top: 40px;
  padding: 16px 28px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

.cta-new-section a.border-btn {
  color: #fff !important;
  border-color: #fff;
  font-family: "Montserrat";
}

.cta-new-section a.border-btn:hover {
  background: #0063c6;
  border-color: #0063c6;
  transform: scale(1.06);
}


.cta-new-section p {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-weight: 500;
  max-width: 1024px;
  margin: 20px auto 0;
}

.cta-wrapper {
  position: relative;
  overflow: hidden;
}

.cta-bg {
  position: absolute;
  top: -20px;
  width: 530px;
  right: -180px;
}

/* Definitions Section */

.definitions-section {
  padding: 50px 20px;
}

.definitions-section .container-new {
  max-width: 1000px;
}

.definitions-section h3 {
  color:#000;
  font-size: 35px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.08em;
  margin: 0rem 0 1.5rem 0;
  text-align: center;
}

.def-txt p,
.def-txt ul li {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0 !important;
  margin-bottom: 10px;
  font-weight: 500;
}

.def-txt:not(.who-we-center) p:last-child {
  margin: 0;
}

.def-txt span {
  color: #000;
  font-weight: 600;
  display: inline-block;
  margin: 0 5px;
}

.def-txt h4 {
  color: #000;
  font-weight: 600;
  margin: 0 0 10px;
}

.def-txt ul li {
  color: #000;
}

.def-txt ul {
  padding-left: 20px;
}

/* Priamry Pages Hero Section  */

.zig-hero-wrap {
  padding: 50px 30px;
}

.bg-overlay {
  position: relative;
}

footer {
  position: relative;
  overflow: hidden;
}

.bg-overlay {
  position: relative;
}

.bg-overlay:after {
  position: absolute;
  background: url(../../../assets/images/home/cta-bg.webp) no-repeat top right;
  height: calc(100% + 55px);
  content: "";
  top: 0;
  z-index: 4;
  background-size: cover;
  top: -39px;
  width: 584px;
  left: -199px;
  opacity: 1;
  z-index: 1;
}

.zig-hero-wrap .container-new {
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  position: relative;
  z-index: 2;
}

.zig-hero-img {
  max-width: 550px;
  transition: 0.3s all;
}

.zig-hero-img:hover {
  transform: scale(1.05);
}

.zig-hero-content {
  width: calc(100% - 515px);
}

.zig-hero-img {
  text-align: right;
}

.zig-hero-img img {
  width: 100%;
  max-width: 100%;
}

.zig-hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.zig-hero-content h3 {
  color: #fff;
  font-size: 52px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  margin: 0;
  word-wrap: break-word;
  max-width: 600px;
}

.zig-hero-content h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 0;
  color: #fff;
}

.zig-hero-content p {
  font-size: 15px;
  line-height: 1.6em;
  color: #fff;
  margin: 25px 0 0;
  max-width: 485px;
}

.zig-hero-content a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 9px;
  border: 1px solid #fff;
  display: inline-block;
  margin-top: 30px;
  padding: 15px 30px;
  transition: 0.25s all ease-in-out;
  background-color: transparent;
  text-align: center;
  max-width: 220px;
  font-family: "Montserrat";
}

/* .zig-hero-content a:hover {
  background-color: #fff;
  color: #0063c6;
} */

/* secondary pages hero */

.sec-hero {
  padding: 70px 20px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sec-hero h3 {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-top: 0;
}

.sec-hero h2 {
  font-weight: 400;
  font-size: 42px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  margin: 0;
}

.sec-hero h4{
  color: #fff;
  text-align: center;
  font-weight: 500;
}

.sec-hero p {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
  max-width: 800px;
  margin: 25px auto 0;
}

.sec-hero h2,
.sec-hero h3,
.sec-hero p {
  text-align: center;
  color: #fff;
}


/* Request Demo Section  */

.request-demo-section {
  padding: 50px 16px;
  text-align: center;
  color: white;
}

.request-demo-section h3 {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.1em;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.request-demo-section p {
  max-width: 947.73px;
  margin: 0 auto;
  font-size: 15px;
  padding: 0;
  line-height: 1.6rem;
}

/* Footer Section  */

.footer-flex {
  display: flex;
  justify-content: space-between;
  padding: 75px 16px;
  flex-wrap: wrap;
  gap: 5rem;
}

.footer-logo-new {
  display: block;
  width: 280px;
  margin-top:-5px;
}

.footer-logo-new img {
  width: 100%;
}

.footer-links ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-links h4 , .footer-links h5{
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin-top: 0;
  margin-bottom: 20px;
}

.footer-links h5{
  margin-top: 30px;
}

.footer-links ul li a {
  font-size: 15px;
  font-weight: 500;
  color: white;
  line-height: normal;
  text-decoration: none;
  padding: 0;
  margin-bottom: 12px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.3s all;
}

.footer-links ul li a:hover {
  transform: scale(1.1);
}

.footer-social {
  display: flex;
  flex-direction: column;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.footer-social p {
  font-size: 15px;
  font-weight: 400;
  color: white;
  margin: 0;
  line-height: 22px;
}

.footer-social p:last-child{
  margin-top: 20px;
}

.socials span {
  font-size: 11px;
  letter-spacing: 0.16em;
  font-weight: bold;
  color: white;
}

.social-links li a:hover {
  transform: scale(1.1);
}

.footer-flex {
  position: relative;
  z-index: 2;
}

.footer-flex > div:first-child {
  display: flex;
  flex-direction: column;
  gap: 95px;
}

.desk-hide {
  display: none;
}

/* Animated Section  */

.animate-section {
  background: #71cdff;
  padding: 60px 20px 46px;
  position: relative;
  overflow: hidden;
  margin-top: 60px;
}

.animate-section:after {
  position: absolute;
  background: url(../../../assets/images/home/cta-bg.webp) no-repeat top left;
  height: 105%;
  content: "";
  top: 0;
  z-index: 4;
  background-size: cover;
  top: -20px;
  width: 530px;
  right: -180px;
}

.animate-section .container-new {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.choose-socialpro {
  max-width: 500px;
  padding-bottom: 33px;
}

.choose-socialpro h2 {
  color: #fff;
  font-size: 46px;
  line-height: 35px;
  margin: 0;
}

.animated-heading {
  width: calc(100% - 500px);
  padding-left: 60px;
  position: relative;
  padding: 20px 0 20px 60px;
}

.animated-heading:after,
.animated-heading:before {
  width: 100%;
  height: 55px;
  left: 0;
  position: absolute;
  content: "";
  top: -1px;
  z-index: 1;
  background: linear-gradient(to bottom, #71cdff 31%, #71cdff47 97%);
  top: 6px;
  z-index: 1;
}

.animated-heading:before {
  bottom: 20px;
  transform: rotate(180deg);
  top: auto;
  height: 59px;
}

.animated-heading h5 {
  font-size: 47px;
  line-height: 35px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 30px;
  color: #005185;
  opacity: 0.4;
}

.animated-heading .slick-current h5 {
  color: #005185;
  opacity: 1;
}

.animated-heading .slick-slide {
  border: 0;
}

/* Slick Slider Settings  */
.contact-slider .slick-dots {
  position: static;
}

.contact-slider .slick-dots li,
.contact-slider .slick-dots li button {
  height: 10px;
  width: 19px;
  transition: 0.2s all linear;
}

.contact-slider .slick-dots li {
  margin: 0 3px;
}

.contact-slider .slick-dots li button {
  background: #0064c4;
  border: 0;
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
  outline: none;
  border-radius: 4px;
  padding: 3px;
}

.contact-slider .slick-dots li.slick-active button,
.contact-slider .slick-dots li.slick-active {
  width: 40px;
}

.contact-slider .slick-dots li button:before {
  opacity: 0 !important;
}

.contact-slider .slick-dots li.slick-active button {
  background: #00aaff;
}

.contact-slider .slick-slide {
  padding-bottom: 20px;
}

.contact-slider .slick-dots {
  bottom: 0;
}

/* new-form-section for homepage */

.forms-section.bg-overlay:after {
  left: auto;
  right: -56px;
  background-position: left center;
}

.forms-section .container-new {
  display: flex;
}

.forms-section {
  padding: 40px 20px 70px;
}

.col-left {
  width: calc(100% - 500px);
  padding-right: 30px;
}

.forms-section .col-left{
  padding-right: 130px;
  width: 100%;
}

.col-right {
  max-width: 600px;
  width: 100%;
}

.col-left h4 {
  text-align: left;
}

.forms-section h2 {
  font-weight: bold;
  font-size: 46px;
  line-height: 1.2em;
  color: #000;
  padding: 0;
  margin: 0 0 20px;
  max-width: 522px;
}

.forms-section h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4em;
  color: #000;
  margin: 10px 0 15px;
  max-width: 430px;
}

.col-left ul {
  margin: 0;
  padding-left: 20px;
}

.col-left ul li {
  font-size: 16px;
  line-height: 1.6em;
  color: #000;
  margin-bottom: 10px;
  font-weight: 500;
}

.col-right form,
.col-right .contact-form-new {
  max-width: 100%;
  width: 100%;
}

.col-right .contact-form-btn {
  margin: 0;
}

#apiInteg {
  padding-top: 30px;
}

.hero-img {
  max-width: 690px;

}

.hero-new .container-new {
  position: relative;
  max-width: 1387px;
}

/* others pages styles */
.reportingSection .featrure-tabs-buttons {
  margin-top: 0;
}

/*logos style*/

.logo-img img {
  max-width: 150px;
  max-height: 100%;
}

.logos-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: -45px;
}

.logos-wrapper h3 {
  text-align: center;
  color: #b3b3b3;
  margin: 0 0 65px;
  letter-spacing: 0.16em;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 500;
}

.logos-wrap .logo-img {
  width: calc(25% - 20px);
  display: flex;
  justify-content: center;
  padding: 0;
  min-height: 72px;
  height: 82px;
  align-items: center;
  transition: 0.3s all;
  margin: 0 10px 45px;
}

.logos-wrapper {
  padding: 70px 15px;
}

/* accordion style */
.accordion-wrap {
  padding: 70px 20px;
}

.accordion-wrap .container-new {
  max-width: 900px;
}

.accordion-item {
  margin-bottom: 0;
  border-radius: 0 !important;
  border-bottom: 1px solid rgb(0 99 198 / 52%);
  background: transparent;
}

.accordion-wrap .Mui-expanded {
  margin: 0;
}

.accordion-item:before {
  display: none;
}

.accordion-title {
  padding: 15px 20px;
}

.accordion-title > div {
  font-size: 16px;
  color:#000;
  margin: 0;
  line-height: 28px;
  font-weight: 700;
}

.accordion-title .MuiAccordionSummary-content {
  padding-right: 20px;
  margin: 0;
  font-family: "Montserrat";
}

.accordion-wrap .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.accordion-content {
  padding: 0 20px;
}

.accordion-content p {
  margin-top: 0;
  color: #000;
  font-size: 14px;
  line-height: 20px;
}

.accordion-content ul {
  padding-left: 20px;
}

.accordion-content ul li {
  color: #000;
  margin-bottom: 5px;
}

.accordion-content ul li ul {
  list-style: disc;
  margin: 14px 0 20px;
}

.accordion-section {
  padding: 70px 20px;
}

.accordion-section .container-new {
  max-width: 1000px;
}

.accordion-section .accordion-wrap {
  padding: 0;
}

.support-section .accordion-wrap {
  margin-top: 40px;
}

.accordion-section h2 {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  margin: 0;
  color: #000;
  text-align: center;
}

.def-txt img {
  max-width: 150px;
  margin: 35px auto 20px;
  display: block;
}

.def-txt.who-we-are p {
  text-align: center;
}

.who-we-center p {
  margin: 0 auto;
  max-width: 852px;
}

/* cards-section(implementation page ) */
.cards-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cards-wrap .card-box {
  padding: 30px 20px;
  border-radius: 5px;
  width: calc(25% - 20px);
  margin: 0 10px 20px;
  transition: 0.3s all;
  background: linear-gradient(55deg, #fff 1%, #a5daff);
}

.cards-wrap .card-box h4 {
  color: #000;
  font-size: 18px;
  line-height: 26px;
  margin: 12px 0;
}

.cards-wrap .card-box h5 {
  color: #000;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-weight: 400;
}

.cards-wrap .card-box p {
  color: #000;
  font-size: 14px;
  margin: 0;
}

.cards-wrapper {
  padding: 70px 20px 50px;
  background: #FBFBFA;
}

.cards-wrap .card-box:hover {
  box-shadow: 0 7px 15px 3px #a5daff;
}

.who-we-are-wrap.cards-wrapper {
  padding: 45px 0 0;
}

.who-we-are-wrap h3 {
  margin-bottom: 60px;
}

.who-we-are-wrap .cards-wrap .card-box {
  width: calc(33.33% - 20px);
}

.cards-wrap .card-box img {
  width: 80px;
  margin-bottom: 20px;
}

/* question cta */
.question-cta {
  padding: 50px 20px;
  background: #71cdff;
  text-align: center;
  overflow: hidden;
}

.question-cta.bg-overlay:after {
  top: -20px;
  width: 530px;
  right: -180px;
  left: auto;
  opacity: 1;
}

.question-cta h3 {
  color: #fff;
  color: #fff;
  font-size: 38px;
  line-height: 1.2;
  margin: 0;
}

.question-cta p {
  color: #054f81;
  font-size: 20px;
  margin: 30px 0 30px;
}

a.btn-dark-blue {
  background: rgb(0, 99, 198);
  padding: 16px 20px;
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  transition: 0.3s all;
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
  font-family: 'Montserrat';
}

a.btn-dark-blue:hover {
  background: #0099fa;
}

.question-cta a.btn-dark-blue {
  padding: 16px 30px;
}

/* Blogs */
.blog-section {
  padding: 70px 20px;
}

.blog-section .blog-detail {
  padding: 0 10px;
}

.blogs-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
}

.blog {
  width: calc(33.33% - 30px);
  background: #F5FAFE;
  border-radius: 20px;
  padding: 0 20px 30px;
  transition: 0.3s all;
  margin: 0 15px 30px;

}

.blog:hover {
  box-shadow: 0 10px 15px #0000001a;
}

.blog-img {
  margin: 0 auto;
  overflow: hidden;
  margin: 0 -20px;
  border-radius: 10px 10px 0 0;
}

.blog-img a {
  height: 250px;
  display: block;
}

.blog-img div img {
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 100% !important;
}

.blog-img a:hover img {
  transform: scale(1.3);
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.blog h4 {
  margin: auto 0 0;
  padding-top: 20px;
  color:black;
  font-weight: 500;
  font-size: 13px;
}

.blog-img img {
  max-width: 100%;
  transition: 0.5s all;
}

.blog h3 {
  margin: 25px 0 0;
}

.blog h3 a {
  font-size: 17px;
  font-weight: bold;
  line-height: 1.2em;
  margin: 0;
  color: #000000;
  text-decoration: none;
}

.blog h3 a:hover {
  text-decoration: underline;
}

.blog p {
  margin-bottom: 0;
  color: #000000;
}

.forms-section .accordion-wrap {
  padding: 0;
}

.blog-detail h1 {
  color: #000000;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0.08em;
  line-height: 1.4;
  margin: 30px 0;
  margin-top: 0;
}

.blog-detail h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  margin-top: 0;
  text-transform: uppercase;
  color: #000000;
  letter-spacing: 0.16em;
}

.blog-detail h4 {
  font-size: 20px;
  line-height: 30px;
  color: rgb(103, 110, 126);
  font-weight: 400;
}

.blog-detail .blog-det-img {
  min-height: 232px;
  position: relative;
  padding-bottom: 40%;
  margin: 40px 0 30px;
  display: block;
}

.blog-detail .blog-det-img img {
  position: absolute;
  top: 0;
  width: 100% !important;
  height: 100%;
  left: 0;
  object-fit: cover;
}

.blog-detail .text p {
  color: #000;
  font-size: 16px;
  line-height: 1.4;
}

.loginPage .social-link a {
  color: #fff;
}

.loginPage {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #0288E1 0%, #055E99 100%);
}

.loginBackground {
  position: absolute;
  opacity: 0.05;
  width: 65vw;
  pointer-events: none;
}

.loginCard {
  width: 100%;
  max-width: 450px;
  background: rgb(255 255 255 / 15%);
  border-radius: 16px;
  padding: 61px 45px;
  position: relative;
  min-height: 488px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-detail .text ol,
.blog-detail .text ul {
  padding-left: 22px;
}

.blog-socials {
  display: flex;
  align-items: center;
}

.blog-socials span {
  padding-right: 20px;
  color: #000;
  font-weight: 500;
  font-family: 'Montserrat';
}

.blog-socials a {
  margin: 0 10px 0 0;
  padding: 6px 7px;
  border: 1px solid rgb(103, 110, 126);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.blog-socials a:hover {
  background: #ffffff73;
}

.blog-socials a img {
  width: 18px;
}

.blog-detail .text ul li,
.blog-detail .text ol li {
  color: #0063c6;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 5px;
}

.blog-detail .text ul,
.blog-detail .text ol {
  margin: 20px 0;
}

.navbar-desk {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.navbar-desk ul {
  padding-left: 50px;
}

.navbar-desk ul li {
  margin-left: 40px;
  position: relative;
}

.header-btn {
  column-gap: 20px;
  display: flex;
}

.header-btn a {
  font-family: "Montserrat";
}

.header-btn a {
  font-family: "Montserrat";
}

.header-btn a:first-child {
  background: #054f81;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.04);
  }
}

.navigation ul.dropdown-content li a {
  font-size: 14px;
}


/* new -sections */
.btns-wrap{
  display: flex;
  gap:20px;
}

body table tbody tr td:first-child div p{
  font-size: 14px
 }
 
 .forms-section h2 > span{
  display: block
 }
 
 .zigzag{
  display: flex;
  gap:70px;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  padding: 50px 45px;
  background: #fff;
  border-radius: 30px;
  border: 1px solid #4341410f;
 }

 .zig-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
 }
 
 .zig-content {
  width:calc(100% - 430px)
 }

 .zig-img{
  max-width: 430px;
  margin-right: 30px;
 }
 
 .zig-img img{
  width: 100%;
 }

 .spreadsheet .zig-img {
  text-align: center;
 }

 .spreadsheet .zig-img img{
    max-width: 80%;
 }

 .zigzag .zig-content h4{
  color:#1479D7 !important;
  font-weight: 700;
  margin: 0 0 20px;
  font-size: 18px;
 }
 
 .zigzag h3{
  color:#000;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2em;
  padding: 0;
  margin: 0;
  max-width: 450px;
 }

 .zigzag p{
  margin-top: 20px;
 }
 
 .zigzag ul{
  padding-left: 20px;
  margin-top: 20px
 }
 
 .zigzag ul li{
  color:#000;
  margin-bottom: 0
 }

 .zigzag .zig-inner-content{
  max-width: 500px;
 }
 
 .btn{
    border: 1px solid #fff;
    border-radius: 9px;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    margin-top: 18px;
    padding: 13px 28px;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    color: #fff;
    font-family: "Montserrat";
 }
 
 .btn:hover {
   background: #fff;
   color: #054f81
 }

 .btn.btn-grey {
   background: #F2F2F2;
   border-color: #F2F2F2;
   color: #000
 }

 .btn.btn-grey:hover {
   background: rgb(0, 99, 198);
   border-color: rgb(0, 99, 198);
   color: #fff;
 }
 
 .zigzag.zig-reverse{
  flex-direction: row-reverse
 }

 .platform-sect p , .platform-sect h3, 
 .platform-sect h4{
  color: #000
 }
 
 .platform-sect .cta-new-section .btns-wrap{
  justify-content: center;
  margin-top: 10px
 }

 .zigzag .textwrap{
  max-width: 540px;
 }
.zigzag .textwrap p {
  margin: 0 0 20px
}

.zigzag .textwrap h5 {
  margin: 20px 0 5px;
  font-size: 26px;
  line-height: 1.2;
  color: #000
}

.forms-section .hero-img {
  top: 0 !important
}

/* .about-new-section .slick-slide > div , .about-card , .about-new-section .slick-slide{
  height:100% !important
 }
  */
 .about-new-section .slick-track , .about-new-section .slick-list{
  display: flex
 }

 .platform-sect .cta-new-section{
  padding:30px 0 75px
 }

 
.platform-sect.lead-way .zig-content > p{
  margin-bottom: 40px
 }

 .zig-img {
  transition: 0.3s all;
}

.zig-img:hover{
  transform: scale(1.05);
}

/* .two-cols {
  display: flex;
  gap:30px;
  justify-content: space-between;
 } */
 
 .two-cols .blog{
  width: calc(50% - 15px);
  border: 1px solid #4341410f;
  border-radius: 20px;
 }
 
 .two-cols .blog{
  width: 100%;
  margin: 0;
 }
 
 .two-cols .blog .blog-img a{
  height: 176px
 }
 
 .two-cols .blog .blog-img div{
  height: 100%
 }

 .two-cols .blog{
  background: #fff
 }
 
 .cta-new-section  {
  margin: 30px 0 0
 }

 .report-slider{
  padding: 50px  20px;
  background:#FBFBFA ;
 }
 
 
 .report-slider .zigzag{
  margin:0 0 22px;

 }

 /* new section */
 .white-btn{
  background: #fff;
  color:#073151 !important;
 }
 
 .white-btn:hover{
  background:#0071DB ;
  border-color:#0071DB;
  color: white !important
 }

 .spreadsheet {
  padding: 50px 20px;
  background: #073151;
 }
 
 .spreadsheet .zigzag{
   background: #1F4562;
  color: #fff;
  margin-bottom: 30px
 }
 
 .spreadsheet .zigzag h3, .spreadsheet .zigzag h4{
  color: #fff
 }
 
 .signup > div span,  
 .signup > div a{
  display: flex;
  column-gap: 5px;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  margin-top: auto;
  padding-top: 10px;
  font-family: 'montserrat';
  width: max-content;
 }

 .signup > div a:hover{
  text-decoration: underline
 }
 
   
 .signup h3{
  font-size: 22px;
  line-height: 1.2;
  color: #fff;
  margin: 12px 0 10px;
 }

 .signup p{
  color: white;
  max-width: 300px
 }
   
 .signup > div{
  padding:40px;
   background: #1F4562;
  border-radius: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
 }
 .signup {
  display: flex;
  column-gap: 30px
 }
 .signup > div.signup-col-right{
  flex: 2.5;
  display: flex;
  column-gap: 30px;
  justify-content: space-between;
  flex-direction: row;
 }

 .signupform{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
 }
 
 .signupform form{
  width:100%;
  display: flex;
  flex-direction: column;
 }
 .signup-col-right > div{
 flex: 1;
 }
 
 
 .signupform form input{
  padding: 14px 15px;
  background: #355771;
  outline: none;
  border: none;
  border-radius: 7px;
  margin: 10px 0 20px;
  color: white !important;
  font-family: 'Montserrat';
 }
 .signupform form label{
  color: white;
  font-size: 15px;
  font-family: 'Montserrat';
 
 }
 
.website-form-btn, .pricing-form button[type='submit']{
  padding: 14px 15px;
  background: #355771;
  outline: none;
  border: none;
  border-radius: 7px;
  color: white;
   font-family: 'Montserrat';
  width: max-content;
  min-width: 141px;
  cursor: pointer;
  font-weight: 700 !important
 }
 
  
 .website-form-btn:hover, .pricing-form button[type='submit']:hover{
  background:#0071DB
 }

 .about-card .before{
  font-size: 35px;
  text-decoration:line-through;
  font-weight: 700;
  color: #677489;
  line-height: 1.2;
 }
 
 .about-card .after{
  font-size: 35px;
  color: #1373CC;
 }
 
 .servicecards p{
  margin-top: 20px;
  color: black;
 }

 .footer-flex > div:first-child h5{
  margin: 0 0 24px;
  font-size: 15px;
  color: #fff;
 }
   
 .footer-flex > div:first-child{
   flex: 2
 }
 
 .footer-flex .footer-links-rightcol{
 display: flex;
  flex: 3;
  justify-content: space-between
 }
 
 .cardswrapper{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  width: calc(100% + 20px);
 }

 .two-col-wrapper .about-card{
  width: calc(50% - 20px)
  }

  .four-col-wrapper .about-card {
    width:calc(25% - 20px)
   }

  .cta {
    text-align: center;
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
  }
   
   .cta h3{
     font-size: 30px;
    line-height: 1.2;
    margin: 0;
    color: #000;
     }
   
   .cta p{
   margin: 20px 0 0;
   color: #000;
   }

   

.about-card ul{
  padding: 0;
  list-style: none;
  margin: 25px 0 0 
 }
   
 .about-card ul li{
  margin-bottom: 8px;
  color: #000;
  font-weight: 500
 }

 .about-new-section .slick-slide >  div{
  margin: 0 10px 20px
 }
 
 .testimonial .slick-track{
    margin: 0 -7px;
    width: calc(100% + 14px) !important;
 }
 
 .logosection .testimonial{
  max-width: 1290px
 }
 
 .logosection{
  padding: 0 20px 30px
 }

 .two-cols .blog{
  padding: 0 40px 40px;
  height: 100%;
 }
 
 .two-cols .blog .blog-img{
  margin: 0 -40px
 }
 
 .two-cols .blog h3{
  margin-top: 35px
 }

 .spreadsheet .zigzag a{
  background: #fff;
  color: #054F81
 }
 
 .spreadsheet .zigzag a:hover{
  background: rgb(0, 99, 198);
  color: #fff
 }

 .sustain-sect .zig-img{
  margin: -40px 30px -40px 0;
 }

 .navigation ul.megamenu ul{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: space-between;
  margin: 0 -20px 30px;
  padding: 0 0 16px;
  border-bottom: 1px solid #d8d8d8;
 }
 
 .navigation ul.megamenu ul li{
  width: calc(25% - 10px);
  margin: 0 5px 10px;
  padding: 10px;
  border-radius: 10px;
 }

 .navigation ul.megamenu ul li:hover{
  background: #D6F1FF;
 }

 .navigation ul.megamenu {  
  width: 100% !important;
  padding: 35px 40px;
  border-radius: 20px;
  border: 1px solid rgba(19, 19, 19, 0.04);
  min-width: 970px;
  left: -33vw;
 }
 
 .navigation ul.megamenu li a{
  padding: 0;
  color: #000000
 }
 
 .navigation ul.megamenu li a:hover{
  background: transparent;
  color: #0081E1
 }
 
 .navigation ul.megamenu li p{
  margin: 5px 0 0;
  font-size: 12px;
  max-width: 156px;
  color: #7D8087;
  font-weight: 400;
 }

 .grey-btn {
  background: #F8F8F6;
  border-radius: 50px;
  color: #000;
  display: flex;
  width: max-content;
  column-gap: 10px
 }
 
 .grey-btn:hover{
  background: #008CE6;
  color: white
 }
 
 .grey-btn:hover svg path{
  fill:white 
 }

 ul.megamenu h3{
  margin: 0;
  font-size: 20px;
  font-family: 'Montserrat';
  color: #000;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
 }

 
 .mob-nav ul li li p{
   margin: 0;
 }
 
 .mob-nav ul > li.dropdown-link li {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-start;
  column-gap: 10px;
  border-radius: 5px;
  margin-bottom: 7px;
  padding: 10px;
  border-radius: 10px;
 }

 .mob-nav ul > li.dropdown-link li:hover{
  background:#D6F1FF;
 }

 .mob-nav ul > li.dropdown-link li:last-child{
  margin-bottom: 0;
 }
 
 .mob-nav ul li li > div:first-child{
  width: 100%;
 }
 
 .mob-nav ul > li.dropdown-link a{
  padding: 0;
  border: 0
 }
 
 .mob-nav ul li ul li:not(:last-child):hover a{
  color: #0063C6
 }
 .mob-nav ul > li.dropdown-link p{
 margin: 5px 0 0;
  font-weight: 400;
  color: #000 !important;
  font-size: 12px !important
 }
 
 .mob-nav ul > li.dropdown-link  ul{
  padding: 20px 10px
 }

 .mob-nav .mob-nav-inner{
  overflow: auto
 }
 
 .mob-nav .mob-nav-inner > ul{
  height: 85vh
 }

 .about-card svg{
  font-size: 40px !important;
 margin-bottom: 20px !important;
 color: rgb(20, 121, 215) !important;
}

.cards-wrapper .about-card{
  margin: 0 10px 20px
 }

 .cards-wrapper .zigzag{
  margin-bottom: 20px;
 }
 .cards-wrapper .zigzag .zig-inner-content{
  max-width: 100%
 }

 .cards-wrapper .zigzag h3{
  max-width: 100%
 }

 .api-section .about-card img{
  max-width: 80%;
  margin: 0 auto;
 }
 
 .api-section .about-card{
  justify-content: center;
  min-height: 240px;
 }

 .cards-wrapper.report-slider .about-card{
  margin: 0
 }
 
 .cards-wrapper.report-slider {
  padding: 70px 20px 50px
 }

 .navigation ul.megamenu ul li a{
  display: flex;
 column-gap: 10px;
}

.navigation ul.megamenu ul li a h5{
margin: 0;
font-size: 14px;
color: #000;
font-weight: 500;
line-height: 18px
}

.navigation ul.megamenu ul li div:last-child{
  width: 100%
 }

 ul li.pricing a:after{
  position: absolute;
  left:0;
  bottom:0;
  content:'';
  width:80%;
  background: #fff;
  height: 2px;
  left: 50%;
  transform: scale(0) translateX(-50%);
  transition: transform 0.3s ease-out;
  transform-origin: left center
 }
 
 ul li.pricing a:hover::after{
   transform: scale(1) translateX(-50%);
 }

 .sub-header ul li.pricing a:after{
  background: #000;
 }

 .cards-wrapper .cta:first-child{
  padding-top: 0
 }
 
 .cards-wrapper .cta.cta-last-child{
  padding-top: 40px
 }

 .cards-wrapper .ourimpact img{
  max-width: 350px
 }
 
 .cards-wrapper .ourimpact.zigzag{
  align-items: flex-start
 }

 .ourimpact.zigzag h3{
  max-width: 400px
 }
 
 .ourimpact.zigzag p{
  max-width: 491px
 }

 .zigzag.zigzag-form .zig-img{
  transform: unset;
  margin: 0
 }
 
 .zigzag.zigzag-form{
  padding: 50px;
  align-items: flex-start
 }
 
 .zigzag.zigzag-from h3{
  max-width: 400px
 }
 
 .zigzag.zigzag-form p{
  max-width: 450px;
  margin: 20px 0;
  font-size: 13px;
 }  

 form label{
  font-size: 16px;
  color: #000;
  font-weight: 400;
  font-family: 'Montserrat';
  line-height: 20px;
  margin-bottom: 7px;
  display: block
 }
 
 form select{
    color: #000 ;
    padding: 16px 17px ;
    border: none;
    background-color: #F6F8FA;
    width: 100%;
    border-radius: 10px;
    font-family: "Montserrat";
    border: 1px solid #DFE5ED;
    outline: transparent;
    outline: none;
    margin-bottom: 30px;
    font-size: 16px;
 }
 
 form select option{
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
 }
 
 .contact-form-new h4{
  margin-bottom: 30px
 }
 
 .checkbox-div {
  margin: 10px 0 20px
 } 
 
 .checkbox-div .MuiTypography-root{
  font-size: 16px;
  line-height: 20px;
  font-family: "Montserrat";
  font-weight: 500;
  padding-left: 10px
 }
 
 
 .checkbox-div .MuiCheckbox-root{
  padding: 5px
 }
 
 .checkbox-div .checkbox-label{
  margin-bottom: 20px
 }
 
 .pricing-form{
  padding: 18px 30px;
  border:1px solid #C0C8D1;
  border-radius: 10px
 }
 
 .zigzag.zigzag-form .zig-content{
  width:calc(100% - 500px);
  padding-right: 20px;
 }
 
 .zigzag.zigzag-form .zig-form{
  width: 550px;
  max-width: 100%
 }
 
 .zigzag.zigzag-form{
  gap:0
 }
 
 .zigzag.zigzag-form .zig-inner-content{
  max-width: 500px
 }
 
 .inputs-cont {
  display: flex;
  flex-wrap: wrap;
  gap:20px 15px;
  justify-content: space-between
 }
 
 .inputs-cont .input-wrap{
  flex-grow: 1
 }
 
 .pricing-form button[type='submit']{
  width: 100%;
  min-width: 100%;
 }

 .book-demo {
  background: #FBFBFA;
 }
 
 .book-demo .container-new{
  flex-direction: column;
 }
 
 .demo-content{
  max-width:600px;
  text-align: center;
  margin: 0 auto 40px;
 }
 
 .contact-form .inputs-cont .input-wrap{
 width:calc(50% - 60px)
 }
 
 .contact-form{
  padding: 40px;
  margin: 0;
  background: #fff;
  border-radius:30px;
  border:1px solid #4341410f;
 }
 
 .contact-form form{
   max-width: 1100px;
  margin: 0 auto
 }
 
 .recaptcha {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-items: center;
 }
 
 .recaptcha button{
  margin: 0
 }
 
 .contact-form .inputs-cont{
  gap:30px
 }

 .footer-social.mobile-show{
  display: none;
 }

 .signup-col-right > div:last-child {
  max-width: 400px;
  display: flex;
  align-items: flex-end;
  widows: 100%;
  
 }
 
 .signup-col-right > div{
  flex: 1
 }
 
 .signup-col-right > div:last-child form{
  width: 100%
 }
 
 .signup-col-right > div:last-child form input{
  background: #355771;
  border-color: #355771;
  color: #fff;
  padding: 16px 17px ;
  border: none;
  width: 100%;
  border-radius: 10px;
  font-family: "Montserrat";
  outline: transparent;
  outline: none;
 }
 
 .recaptcha > div{
      margin-right: -13px;
 }
 
 .recaptcha button {
     margin: 0 36px 0 0;
 }

 .blogs-slider {
  background: #FBFBFA;
  padding: 0 20px 30px;
  margin-top:-20px;
 }

 .contact-form .recaptcha > div {
  margin-right: 36px;
}
/* Media Queries  */

 
@media(max-width:1300px){
  .navigation ul.megamenu{
    left: -154%;
    padding: 28px 30px;
    min-width: 642px;
  }

  .navigation ul.megamenu ul {
    padding: 0 0 10px;
  }

  .navigation ul.megamenu ul li{
    width: calc(33.33% - 10px);
  }

  .navigation ul.megamenu ul{
    justify-content:flex-start;
  }
 }
 
@media (max-width: 1400px) {
  .hero-new:after {
    background-position: center;
    width: calc(100% + 3vw);
  }
}
@media (max-width: 1200px) {
  .animated-heading h5 {
    font-size: 40px;
  }

  .choose-socialpro h2 {
    font-size: 40px;
  }

  .animated-heading {
    padding-left: 0;
  }

  .hero-img {
    max-width: 550px;
    right: 0;
  }

  .hero-main {
    padding-top: 205px;
  }

  .hero-content {
    width: calc(100% - 550px);
  }

  .widget-2 {
    width: 186px;
  }

  .widget-1 {
    width: 181px;
  }

  .hero-content h1{
    font-size: 53px;
  }
}

@media (max-width: 1100px) {
  .hero-img {
    max-width: 450px;
  }

  .hero-content {
    width: calc(100% - 450px);
  }

  .hero-content h1 {
    font-size: 40px;
  }

  .widget-1 {
    width: 151px;
  }

  .widget-2 {
    width: 157px;
  }

  .zig-content {
    width:calc(100% - 400px)
   }
  

.sustain-sect .zig-img, .platform-zigimg {
  margin: -40px 0 -40px 0;
}

   .zig-img{
    max-width: 400px;
   }

   .zigzag{
    gap: 30px;
   }

   .zigzag h3{
    font-size: 35px;
   }

   .zigzag .zig-content h4{
    font-size: 18px;
    margin-bottom: 10px;
   }

   .about-us .zig-img , .ourimpact .zig-img{
    margin: 0
   }

   .recaptcha {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
   }
   .recaptcha button[type='submit'] {
    min-width: 100%;
   }
}

@media (max-width: 1024px) {
  .accordion-section h2 {
    font-size: 35px;
  }

  .navigation > ul > li {
    position: relative;
  }

  .zig-hero-content h3,
  .sec-hero h2 {
    font-size: 40px;
  }

  .about-cards-section {
    gap: 20px;
  }

  .cooridnate-img {
    max-width: 380px;
    padding-left: 30px;
  }

  .cooridnate-social-content {
    width: calc(100% - 380px);
  }

  .animate-section .container-new {
    flex-direction: column;
  }

  .animated-heading {
    width: 100%;
    text-align: center;
    padding: 30px 0 20px;
  }

  .animated-heading h5 {
    font-size: 34px;
    padding-bottom: 20px;
  }

  .choose-socialpro {
    max-width: 100%;
    text-align: center;
    padding: 0;
  }

  .choose-socialpro h2 {
    font-size: 38px;
  }

  .widget-2 {
    width: 151px;
  }

  .widget-1 {
    width: 154px;
  }

  .inner-feature-container {
    padding: 61px 30px 61px;
  }

  .blog {
    width: calc(50% - 20px);
    margin: 0 10px 30px;
  }

  .forms-section .accordion-wrap {
    padding: 0 20px 0 0;
  }

  .cards-wrap .card-box {
    width: calc(50% - 20px);
  }

  .forms-section .col-left {
    padding-right: 90px;
  }
 
  .forms-section h2 {
      font-size: 33px;
  }

  .zig-content {
    width:calc(100% - 415px)
   }
  
   .zig-img{
    max-width: 400px;
   }

   .zigzag{
    gap: 30px;
   }

   .zigzag h3{
    font-size: 35px;
   }

   .zigzag .zig-content h4{
    font-size: 18px;
   }

   .footer-flex {
    gap:3rem
   }

   .footer-flex .footer-links-rightcol {
    flex-wrap: wrap;
    row-gap: 30px
   }
   
   .footer-flex .footer-links-rightcol > div {
    width: 50%
   }

   
  .four-col-wrapper .about-card {
    width:calc(50% - 20px)
   }

   .navigation ul.megamenu ul{
    justify-content: flex-start
   }
   .navigation ul.megamenu ul li{
    width:calc(50% - 10px)
   }
   .navigation ul.megamenu{
    min-width: 500px;
   }

   .footer-flex .footer-links-rightcol > div.footer-last-col{
    display: flex;
    align-items: flex-start;
    width: 100%;
   justify-content: space-between;
    column-gap: 10px
   }
   
   .footer-flex .footer-links-rightcol > div.footer-last-col .col{
    width: 50%
   }
   
   .footer-last-col h5{
    margin-top: 0
   }
}

@media (max-width: 991px) {
  .col-left {
    width: calc(100% - 400px);
  }

  .col-right {
    max-width: 400px;
  }

  .hero-btns a {
    font-size: 13px;
    padding: 14px 12px;
  }

  .zig-hero-content {
    width: calc(100% - 415px);
  }

  .zig-hero-img {
    max-width: 400px;
  }

  .report-slider .about-cards-section {
    padding-bottom:  50px !important
   }

   .cta-new-section {
    margin: 15px 0 0;
   }
 
   .report-testi.testimonial .slick-track{
    margin: 0;
    width: auto !important;
   }

   .zigzag.zigzag-form .zig-content {
    width: 100%;
    padding: 0
   }
   
   .zigzag.zigzag-form{
    flex-direction: column;
    gap:30px
   }
   
   .zigzag.zigzag-form .zig-form{
    margin: 0 auto;
    max-width: 700px;
    width: 100%
   }
   
   .zigzag.zigzag-form .zig-inner-content{
    margin: 0 auto;
    text-align: center;
    max-width: 600px
   }
   
   .zigzag.zigzag-form  .zig-content p{
    max-width: 570px;
    margin: 10px auto 0
   }
   
   .pricing-form p{
    margin: 15px 0;
   }
}

@media (min-width: 901px) {
  .mob-bar {
    display: none;
  }
}

@media (max-width: 900px) {

  .logosection {
    padding: 0 20px 60px;
  }

  .zig-hero-img {
    max-width: 350px;
  }

  .zig-hero-content {
    width: calc(100% - 365px);
  }

  .zig-hero-content h3 {
    font-size: 42px;
  }

  .logos-wrap .logo-img {
    width: calc(33.33% - 20px);
  }

  .hamburger-menu {
    display: block;
  }

  .navigation ul {
    display: none;
  }

  .nav-login {
    width: max-content;
  }

  .mob-nav ul li:not(:last-child) {
    margin-bottom: 0;
  }

  .mob-nav {
    display: block;
    padding: 50px 40px;
  }

  .mob-bar {
    transition: 0.3s all;
    border-top: 1px solid rgb(0, 99, 198);
    background: #fff;
    left: 0;
    position: fixed;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    z-index: 1000;
    top: 0;
    max-width:100%;
    height: 100%;
    width: 100%;
  }

  .mob-bar.active {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
  }

  .hamburger-menu:hover {
    opacity: 0.6;
  }

  .about-cards-section {
    flex-wrap: wrap;
    gap: 30px;
  }

  .inner-feature-container {
    padding: 43px 30px;
  }

  .featrure-tabs-buttons {
    gap: 0.8rem;
    grid-template-columns: 1fr 1fr;
    margin: 2.5rem auto 2.5rem;
    max-width: 600px;
  }

  .leading-social-content h3 {
    word-break: break-word;
    font-size: 44px;
  }

  .contact-slider-img img {
    max-width: 300px;
    width: 100%;
  }

  .contact-slider {
    width: calc(100% - 350px);
    padding-left: 34px;
  }

  .contact-slider .slick-slide {
    padding-bottom: 16px;
  }

  .hero-main {
    flex-direction: column-reverse;
    gap: 0;
    padding: 125px 16px 0;
  }

  .hero-content {
    width: 100%;
  }


  .footer-flex {
    gap: 30px;
  }

  .footer-social p {
    text-align: left;
  }

  .footer-flex h4 {
    margin-top: 0;
  }

  .cooridnate-social-content h3 {
    letter-spacing: 0.03em;
    font-size: 32px;
    margin: 20px 0;
  }

  .cooridnate-img {
    max-width: 250px;
    padding: 0;
  }

  .cooridnate-social-content a {
    font-size: 13px;
    margin-top: 20px;
    padding: 12px 18px;
  }

  .cooridnate-social-content {
    width: calc(100% - 250px);
    padding-right: 30px;
  }

  .cooridnate-img {
    margin-top: -16px;
  }

  .hero-new {
    padding-right: 0;
    width: 100%;
  }

  .hero-img {
    max-width: 500px;
  }

  .widget-2 {
    width: 151px;
  }

  .widget-1 {
    width: 20vw;
  }

  .widget-2 {
    width: 20vw;
  }

  .hero-content {
    width: 100%;
  }

  .hero-btns a {
    padding: 16px 28px;
    font-size: 15px;
  }

  .animate-section:after {
    height: 112%;
    width: 451px;
  }

  .animate-section {
    padding: 70px 20px 35px;
  }

  .who-we-are-wrap .cards-wrap .card-box {
    width: calc(50% - 20px);
    margin-bottom: 30px;
  }

  .who-we-are-wrap .cards-wrap {
    justify-content: center;
    margin-bottom: -30px;
  }

  .header-btn {
    display: none;
  }

  .hero-new:after {
    background-position: bottom;
    width: calc(100% + 54vw);
    height: 40%;
    left: -1px;
  }

  .hero-new {
    margin-top: -106px;
    padding-bottom: 50px;
  }

  .hero-content h1,
  .hero-content p,
  .hero-content span {
    color: #000;
  }

  .hero-content h1{
    color:#1e354c;
  }

  .hero-btns .request {
    border-color: #05214b;
    color: #05214b;
  }

  .hero-btns .request:hover {
    background: #18509e;
    border-color: #18509e;
    color: #fff;
  }

  .hero-content h1 {
    max-width: 434px;
  }

  .forms-section.formPage .container-new{
    flex-direction: column;
    gap: 30px;
  }

  .forms-section.formPage  .col-left{
    padding: 0;
  }

  .forms-section  .col-right {
    max-width: 100%;
   }

   .forms-section .col-left {
    padding-right: 0;
    padding: 30px 0 0;
  }

  .forms-section h2 {
    max-width: 100%;
  }

  .signup > div.signup-col-right{
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .signup-col-right > div {
    flex: 1;
    width: 100%;
  }
  
  .signup h3 {
    margin: 10px 0;
  }

  .testimonial .slick-track {
    margin: 0 ;
    width: auto !important;
}

.signup-col-right > div:last-child{
   max-width:100%
}
  
}

@media (max-width: 850px) {

  .sustain-sect .zig-content{
    padding-top: 25px;
  }

  .col-left {
    width: 100%;
    padding: 0 0 40px 0;
  }

  .forms-section .accordion-wrap {
    padding: 0;
  }

  .zig-content, .zig-img {
    width: 100% ;
  }

  .zigzag, .zigzag.zig-reverse {
    flex-direction: column-reverse;
    gap: 0;
  }

  .zig-content{
    padding: 20px 0 0;
  }

  .about-card ,  .four-col-wrapper .about-card {
    width: calc(50% - 30px);
  }

  .cardswrapper{
    flex-wrap: wrap;
    justify-content: center;
  }

  
  .cta-new-section {
    margin-top: 10px;
  }

  .about-us .zig-img, .ourimpact .zig-img,
  .about-us .zig-img img, .ourimpact .zig-img img{
    margin: 0 auto;
    text-align: center;
  }

  
 .report-slider .blogs-slider .slick-track .slick-slide{
  width: unset;
  margin: 0
 }
 
 .report-slider .blogs-slider .two-cols{
  margin: 0;
  width: 100%
 }
}

@media (max-width: 767px) {
   
   .about-card .after, .about-card .before{
    font-size: 30px;
   }

   .about-card .after{
    margin: 5px 0 0
   }

  .signup{
    flex-direction: column;
    gap: 30px;
  }

  .about-new-section .about-sec-txt , .platform-sect p{
    font-size: 14px;
    line-height: 21px;
  }

  .card-img {
    margin-bottom: 10px;
  }

  .animate-section{
    margin-top: 30px;
  }
  .about-new.bg-overlay:after {
    width: 400px;
    height: 500px;
    left: -106px;
  }

  .question-cta h3 {
    font-size: 28px;
  }

  .question-cta p {
    font-size: 17px;
    margin: 26px 0 30px;
  }

  /* .mob-bar {
    top: 86px;
  } */

  .zig-hero-wrap .container-new {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .zig-hero-img img {
    width: 100%;
    padding: 0;
    max-width: 100%;
  }

  .zig-hero-content {
    width: 100%;
  }

  .zig-hero-img {
    margin: 0 auto;
  }

  .zig-hero-content h3 {
    font-size: 40px;
  }

  .zig-hero-content p {
    margin: 19px 0 0;
    max-width: 100%;
  }

  .bg-overlay:after,
  .bg-overlay-right:after {
    width: 679px;
    left: -182px;
  }

  .bg-overlay-right:after {
    top: 0;
    bottom: unset;
  }

  .logos-wrapper {
    padding: 50px 10px;
  }

  .logos-wrapper h3 {
    margin: 0 0 21px;
    font-size: 14px;
  }

  .accordion-title > div {
    font-size: 16px;
    line-height: 26px;
  }

  .contact-section {
    flex-direction: column-reverse;
    padding: 50px 15px;
  }

  .contact-slider {
    width: 100%;
    padding: 0 0 50px;
  }

  .contact-slider .slick-slide {
    padding-bottom: 10px;
  }

  .site-logo {
    display: block;
    max-width: 180px;
  }

  .navigation {
    padding: 24px 0;
  }

  .widget-1,
  .widget-2 {
    width: 24vw;
  }

  .inner-feature-container {
    padding: 30px 44px 40px;
  }

  .cooridnate-social {
    flex-direction: column-reverse;
  }

  .cooridnate-social-content {
    width: 100%;
    padding: 10px 0 0;
  }

  .cooridnate-img {
    max-width: 300px;
    margin: 0 auto;
  }

  .cooridnate-social-content h3 {
    max-width: 100%;
    font-size: 23px;
    margin: 10px 0;
    line-height: 28px;
  }

  .cooridnate-social-content .discover,
  .contact-slider-content .discover {
    margin-right: 12px;
  }

  .feature-tab-section,
  .cta-new-section {
    padding: 40px 20px;
  }

  .cta-new-section a {
    font-size: 14px;
    padding: 15px 23px;
  }

  .form-control-new {
    margin-bottom: 18px;
  }

  .contact-form-btn {
    margin-top: 10px;
  }

  .hero-content h1 {
    font-size: 34px;
  }

  .feature-tagline,
  .about-new-section .about-tagline,
  .about-new-section .about-tagline,
  .cta-new-section h3,
  .definitions-section h3,
  .leading-social-content h3,
  .contact-slider-content h3 {
    font-size: 27px;
    line-height: 33px;
  }

  .request-demo-section h3 {
    font-size: 32px;
    margin: 0 0 20px;
  }

  .forms-section {
    padding: 50px 20px;
  }

  .about-card {
    padding: 30px 35px;
  }

  .servicecards p{
    margin-top: 10px;
  }

  .contact-form-new h5 {
    font-size: 27px;
    margin: 20px 0 30px;
  }

  .inner-feature-container {
    border-radius: 17px;
  }

  .cooridnate-social-content ul li {
    font-size: 13px;
  }

  .sec-hero p {
    margin-top: 20px;
  }

  .sec-hero,
  .accordion-wrap {
    padding: 50px 20px;
  }

  .blog-section {
    padding: 50px 20px 30px;
  }

  .sec-hero {
    min-height: 250px;
  }

  footer:after {
    height: 500px;
    left: -252px;
  }

  .cards-wrapper,  
  .cards-wrapper.report-slider {
    padding: 50px 20px 30px;
  }

  .blog-detail h1 {
    font-size: 20px;
    margin: 0;
  }

  .blog-detail h4 {
    font-size: 16px;
  }

  .blog-detail .blog-det-img {
    margin: 30px 0 25px;
    display: block;
  }

  .forms-section h2 {
    font-size: 38px;
  }

  .accordion-section h2 {
    font-size: 32px;
  }

  .support-section .accordion-wrap {
    margin-top: 20px;
  }

  .accordion-section {
    padding: 50px 20px;
  }

  .who-we-are-wrap h3 {
    margin-bottom: 34px;
  }

  .slider-logo-wrap:after,
  .slider-logo-wrap:before {
    width: 66px;
  }

  .slider-logo h3 {
    margin-bottom: 20px;
  }

  .slider-logo-wrap{
    margin: 20px 0 10px;
  }

  .blog-detail .text p {
    font-size: 15px;
    line-height: 1.5;
  }

  .who-we-are-wrap.cards-wrapper {
    padding: 35px 0 0;
  }

  .def-txt img {
    margin: 38px auto 20px;
  }

  .animate-section {
    padding: 44px 20px 16px;
  }

  .about-new{
    padding: 18px 0 70px
  }

  .lead-way .zigzag{
    gap: 30px;
    margin-bottom: 10px;
  }

  .sustain-sect .zig-img, .platform-zigimg {
    max-width: 300px;
   margin: -20px auto;
  } 

  .zigzag h3 {
    font-size: 28px;
    max-width: 100%;
   }

   .zigzag ul li{
     font-size: 14px;
     line-height: 21px;
     margin-bottom: 5px;
   }

   .zigzag .textwrap h5 {
    margin: 20px 0 5px;
    font-size: 18px;
    }

    .zigzag .textwrap p {
      font-size: 14px;
      line-height: 21px;
     }

     #apiInteg {
      padding-top: 0;
    }

    .hero-content p{
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    .hero-btns div{
      justify-content: center;
      gap:20px;
    }

    .hero-btns a {
      padding: 13px 18px;
     }
  
    .hero-btns div a{
      margin: 0;
    }

    .hero-content h1{
      margin: 0 auto;
      text-align: center;
    }

    .platform-sect .cta-new-section {
      padding: 30px 0 50px;
  }

  .platform-sect .cta-new-section{
    padding-top: 0;
  }

  .zigzag{
    padding: 40px 20px;
    }

  .card-img {
    height: 161px;
  }

  .zigzag .hero-btns .discover {
    min-height: 45px;
    padding: 3px 3px 3px 20px;
    font-size: 14px;
  }
  .zigzag .hero-btns .discover img {
  width: 36px;
  height: 35px;
}

.footer-flex > div:first-child h5{
  font-size: 16px;
  line-height: 1.2;
}

.cta h3{
  font-size: 24px;
}

.zig-hero-content h4 {
  font-size: 18px;
}

  .report-slider .cta-new-section{
      margin-top: 0;
  }

  .report-slider{
    padding-bottom: 40px;
  }

  .two-cols .blog {
    padding: 0 30px 35px;
  }
  .two-cols .blog .blog-img{
    margin: 0 -30px;
  }

 .cta {
  padding-bottom: 25px;
 }

 .cards-wrapper .cta.cta-last-child{
  padding-top: 30px;
 }

 .api-section .about-card{
  min-height: 180px;
 }

 .contact-form .inputs-cont .input-wrap {
  width: 100%;
}

.demo-content {
  margin: 0 auto 30px;
}

.recaptcha button[type='submit'] {
  min-width: 180px;
  margin: 0 auto;
}

.contact-form {
  padding: 35px 30px;
}

.contact-form .inputs-cont {
  row-gap: 15px;
}

.demo-content p{
  margin: 0;
}

.forms-section h2{
  margin-bottom: 10px;
}

.cta-new-section , .cta-new-section h3, .cta-new-section p{
  text-align: left;
}

.cards-wrapper .zigzag{
  padding: 30px 35px;
}

.cards-wrapper .ourimpact img {
  max-width: 250px;
  width: 100%;
}

.cta{
  text-align: left;
}

.g-recaptcha div > div > div{
  transform: none;
  width: 180px !important;
  margin-left: -10px
 }
}

@media (max-width: 730px) {
  .animated-heading:after,
  .animated-heading:before {
    width: 100%;
    height: 40px;
  }

  .animated-heading:after {
    top: 16px;
  }

  .blog {
    width: 100%;
    padding: 0 20px 30px;
    margin: 0 0 20px;
  }

  .blogs-wrap {
    margin: 0;
  }

  .zigzag.zigzag-form {
    padding: 40px 30px;
   }
}

@media(max-width:700px){
  .footer-flex{
    flex-direction: column;
  }

  .footer-social p{
    font-size:13px;
    line-height: 18px;
  }

  .footer-flex > div:first-child h5{
    margin-top: 40px;
  }
  .footer-flex .footer-links-rightcol > div.footer-last-col .col{
    width: calc(50% - 5px)
   }

   .footer-flex > div:first-child{
    gap:40px
   }
   .footer-flex{
    gap:50px
   }
}
@media (max-width: 640px) {

  .api-section .about-card img{
    max-width: 200px;
   }

  .signup > div{
    padding: 35px 30px;
  }

  .about-new-section .slick-slide > div {
    margin: 0 0 10px;
   }

  .footer-flex {
    padding: 50px 30px;
  }

  .definitions-section .def-txt {
    padding: 0 10px;
  }

  .who-we-are-wrap .cards-wrap .card-box {
    width: 100%;
    margin: 0 0 30px;
  }

  .logos-wrap {
    margin-bottom: -20px;
  }

  .logos-wrap .logo-img {
    width: calc(50% - 20px);
    margin-bottom: 20px;
  }

  .accordion-section h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .accordion-title {
    padding: 10px 9px 10px 5px;
  }

  .accordion-content {
    padding: 0 5px;
  }

  .accordion-content p {
    font-size: 13px;
  }

  .zig-hero-content h3,
  .sec-hero h2 {
    font-size: 28px;
  }

  .choose-socialpro h2 {
    font-size: 24px;
  }

  .animated-heading h5 {
    font-size: 30px;
    padding-bottom: 10px;
    line-height: 35px;
  }

  .footer-logo-new {
    width: 180px;
  }

  .hero-content h1 {
    font-size: 32px;
    max-width: 338px;
    line-height: 36px;
    font-weight: 800;
  }

  .hero-content p {
    margin: 20px 0;
    font-size: 14px;
    line-height: 21px;
  }

  .forms-section h2 {
    font-size: 28px;
  }

  .card-img {
    height: 131px;
  }

.two-cols {
  gap:30px;
  flex-wrap: wrap;
 }
 
 .two-cols .col{
  width: 100%
 }

 .about-card, .two-col-wrapper .about-card,.four-col-wrapper .about-card{
  width: 100%;
 }
 .checkbox-div .MuiTypography-root {
  font-size: 14px;
  line-height: 17px;
  padding-left: 8px;
}

.checkbox-div .MuiCheckbox-root {
  padding: 0;
}

.checkbox-div .MuiFormControlLabel-root{
  margin: 0 0 10px;
}

.zigzag.zigzag-form {
  padding: 40px 21px;
}

.pricing-form {
  padding: 16px;
}

.sustain-sect .zig-img, .platform-zigimg {
  max-width: 250px;
} 


}

@media (max-width: 600px) {
  h1,
  h2,
  h3,
  h4 {
    word-break: break-word;
  }

  .cooridnate-img {
    max-width: 80%;
    padding: 0;
  }

  .contact-slider-content h3 {
    margin: 15px auto;
    max-width: 100%;
  }

  .hero-main {
    gap: 10px;
  }

  .slider-logo h3 {
    font-size: 14px;
  }

  .cards-wrap .card-box {
    width: 100%;
    margin: 0 0 20px;
  }

  .slider-logo{
    padding-bottom: 10px;
  }

  .feature-txt-new {
    padding: 0 10px;
  }

  .hero-img {
    max-width: 400px;
  }

}

@media (max-width: 479px) {
  .footer-links ul li a {
    margin: 0 0 8px;
    font-size: 14px;
  }

  .footer-links h4, .footer-links h5 {
    margin-bottom: 10px;
  }

  .desk-hide {
    display: block;
    margin-top: 10px;
  }

  .mob-hide {
    display: none;
  }

  .social-links {
    justify-content: flex-start;
  }

  .slider-logo-wrap:after,
  .slider-logo-wrap:before {
    width: 50px;
  }

  .animated-heading h5 {
    font-size: 20px;
    line-height: 30px;
  }

  .footer-flex {
    gap: 0;
  }

  .footer-flex > a,
  .footer-flex > div {
    width: 100%;
    padding: 0 0 40px;
  }

  .footer-flex > div:last-child {
    padding-bottom: 0;
  }

  .social-links {
    justify-content: flex-start;
  }

  .footer-social p {
    text-align: left;
  }

  .footer-logo-new {
    margin: 0;
  }

  .social-links a img {
    width: 30px;
  }

  .social-links {
    gap: 10px;
  }

  .inner-feature-container {
    padding: 29px 30px 47px;
  }

  .cooridnate-social-content .discover,
  .cooridnate-social-content a,
  .contact-slider-content .discover,
  .contact-slider-content .request {
    display: block;
    text-align: center;
    width: 100%;
  }

  .featrure-tabs-buttons button,
  .featrure-tabs-buttons .active-tab,
  .featrure-tabs-buttons button:hover {
    border-radius: 10px;
    padding: 14px 14px;
    font-size: 14px;
  }

  .contact-slider-content a {
    margin-top: 15px;
  }

  .contact-slider-content .discover {
    margin-top: 20px;
  }


  .slider-logo img {
    max-width: 55%;
  }

  .logo-img img {
    max-width: 98px;
    max-height: auto;
  }

  footer:after {
    height: calc(100% + 55px);
    left: -30%;
  }

  .animate-section{
    padding: 34px 14px 8px;
  }

  .hero-content h1 {
    font-size: 27px;
    line-height: 38px;
  }

  .footer-flex .footer-links-rightcol{
    flex-direction: column;
  }

  .footer-flex .footer-links-rightcol > div.footer-last-col{
    flex-direction: column;
    gap:20px
  }

  .footer-flex .footer-links-rightcol {
    gap:20px
  }

  .footer-social h5{
    margin: 0 0 10px;
  }

  .footer-flex > .footer-links .footer-social{
    display: none;
  }
}

@media (max-width:375px){
  .hero-btns a {
    padding: 11px 13px;
    font-size: 12px;
}


.hero-content h1 {
  font-size: 25px;
  line-height: 32px;
}

.g-recaptcha div > div > div{
  transform: none;
  width: 45px !important;
  margin-left: -10px
 }
}

/* Animation  */
@keyframes slideY-new {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}
.stepMarquee {
  background: url(../../../assets/images/dashboard/formBg.png);
  background-size: cover;
  height: 100%;
  background-position: center;
}

/* .dashboardPage {
  background: linear-gradient(to right, #0055c6 0%, #0089de 40%, #01ccfc 100%);
}

.logoMonochrome {
  filter: grayscale(2) brightness(4);
} */

.sub-header ul li span {
  color: #000;
}

.sub-header ul li span:hover {
  color: #525556;
}

.loginCard .MuiFormControl-root {
  max-width: 363px;
  width: 100%;
  margin: auto !important;
  margin-top: 10%;
}

.loginCard form {
  text-align: center;
}

.loginCard button:hover {
  transform: scale(1.04);
}

.impactCards {
  padding-top: 40px;
  padding-bottom: 0;
 }

 .testimonial .slick-track .card-img{
  width: 80px;
  height: 30px;
  margin: 0 0 0 auto
 }
 
 .testimonial .slick-track .card-img img{
  object-fit: contain;
 }
 .testimonial .personName{
  color: black;
  margin: 10px 0 0
 }
 
 .testimonial .companyName{
  color: #939c9f;
  margin: 0 0 10px;
  font-size: 12px;
  font-weight: 500
 
 }
 
 .testimonial  .about-card{
  gap:0;
  background: white;
  border:1px solid #4341410f;
 }
 
 
 .testimonial  .about-card:hover{
   box-shadow: 0 5px 10px #0000001a
 }
 .testimonial .slick-track {
  gap: 0;
  display: flex;
  justify-content: space-between; 
 }
 
 .testimonial .slick-track  div{
 /* height: 100% !important; */
  align-items: stretch;
  display: flex;
  width: 100%;
 }

 .report-slider .testimonial{
  padding-left: 0;
  padding-right: 0;
 }

 .capabilityWrap {
  display: flex;
  gap:50px;
  align-items: flex-start;
  margin: 50px 0 0;
 }
 
 .capabilityWrap.def-txt.who-we-are p{
  text-align: left;
  width:calc(100% - 200px);
  padding-right: 46px;
  font-weight:500
 }
 
 .capabilityWrap.def-txt img{
  margin: 0;
  max-width: 200px
 }

 .d-none-dk{
   display:none
 }
 
 /*login page styles*/

 .loginCard .MuiOutlinedInput-root {
  border:0
 }
 
 .loginCard .MuiOutlinedInput-root input{
    box-shadow: unset;
    background: #fff;
    padding: 10px 14px;
    min-height:23px;
    color:#717680 !important;
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border:1px solid #D5D7DA;
    border-radius: 5px
 }
 
 .loginCard .MuiOutlinedInput-root input::placeholder{
  color:#717680 !important;
  border-color:#054F81 !important
 }
 
 .loginCard  .Mui-error {
  margin: 2px 0;
  color:#fff;
  text-shadow: none;
 }
 
 .loginCard .MuiOutlinedInput-root fieldset{
    border: 0
 }
 
 body form .MuiInputBase-input{
  height: 20px
 }
 
 body .loginCard .MuiButton-root {
  width:100%;
  border-radius: 5px;
  padding: 10px 14px;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
 }

 .loginCard label{
  text-align: left;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #fff
 }
 
 body .loginCard .MuiButton-root:hover{
  background: #054F81
 }
 
 .logincardLink{
  text-align: left;
 }
 
 .logincardLink a {
  color: #fff;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none !important
 }
 
 .logincardLink a:hover{
  text-decoration: underline !important
 }


 @media (max-width:767px){
 

  .capabilityWrap {
    gap:30px;
   }
   .capabilityWrap.def-txt{
    padding: 0;
   }
 }


 @media (max-width:700px){
  .capabilityWrap {
    gap:30px;
    margin: 20px 0 0;
    flex-direction: column-reverse;
   }
   .d-none-mb{
    display:none !important
  }
  .d-none-dk {
    display: block;
    max-width: 200px;
    margin: 0 auto 20px;
}

   .capabilityWrap.def-txt.who-we-are p{
    width:100%;
    padding-right: 0;
    text-align: center;
   }
   .capabilityWrap.def-txt img{
    margin: 0 auto;
   }

 }

 
 @media (max-width:479px){
  .capabilityWrap {
    margin: 20px 0 0;
   }
  
   .capabilityWrap.def-txt img{
    max-width: 150px;
   }

   .d-none-dk {
    max-width: 150px;
   }

   .testimonial .slick-track > div{
    margin: 0;
   }

   .footer-flex .footer-links-rightcol > div{
    width: 100%;
   }

   .footer-social.mobile-show{
    display: block;
   }
 }

 .sustain-sect{
  margin: 10px 0
 }

@media (min-width:991px){

  .report-slider .slick-track {
    transform: none !important;
   flex-wrap: wrap
  }
  
  .report-slider .slick-track .slick-slide{
   width: 33.33% !important;
   height: auto !important
  }
  
  .report-slider .slick-list {
   overflow: unset 
  }
}

  
@media(min-width:851px) {
  .blogs-slider .slick-track .slick-slide {
    width: calc(50% - 30px) !important;
    margin: 0 15px;
    padding-bottom: 30px;
  }

  .blogs-slider .slick-track {
    transform: none !important;
    flex-wrap: wrap;
    margin: 0 -15px;
    width: calc(100% + 30px) !important
  }

  .blogs-slider .slick-list {
    overflow: unset;
  }
}
 
 @media(max-width:850px){
     .blogs-slider .slick-track {
        margin: 0;
        width: auto !important;
        display: flex;
    }

    .blogs-slider .slick-list {
      display: flex;
    }
    .blogs-slider {
      padding-bottom: 60px;
    }

    .blogs-slider .slick-dots{
      bottom: -40px;
    }
 }