@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Roboto";
  align-items: "center";
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.g-recaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
}

/* ::-webkit-scrollbar{
  width:10px;
}

::-webkit-scrollbar-track{
  background:#e0e0e0
}

::-webkit-scrollbar-thumb{
  background:#888
}

::-webkit-scrollbar-thumb:hover{
  background:#555
}  */

/* Custom scrollbar styles */
.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #3f99fa;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Calendar Styles */
body .MuiDateCalendar-root {
  padding: 20px 0 0 !important
}

.MuiPickersCalendarHeader-root {
  margin: 0 0 20px 0;
  padding: 0;
}

.MuiPickersCalendarHeader-root {
  position: relative;
  display: block;
}

.MuiPickersCalendarHeader-labelContainer {
  width: 100%;
  margin: 0;
  /*  padding: 0 20px; */
  justify-content: center
}

.MuiPickersCalendarHeader-switchViewButton {
  margin-right: 0
}

.MuiPickersArrowSwitcher-button {
  position: absolute;
  left: 0;
  top: 0
}

.MuiIconButton-edgeStart {
  right: 0;
  left: auto
}

.MuiDateCalendar-root {
  padding: 15px
}

.MuiDayCalendar-header,
.MuiDayCalendar-weekContainer {
  justify-content: space-between
}

.MuiDayCalendar-weekDayLabel {
  margin: 0
}

.MuiDateCalendar-root {
  max-height: 320px
}

.MuiDayCalendar-weekDayLabel {
  font-size: 14px;
  color: #414651;
  font-weight: 500
}


.MuiPickersDay-root {
  font-size: 14px;
  color: #414651;
  font-weight: 400
}

body .MuiPickersDay-today {
  border-color: #0055C6 !important
}

body .calendarfieldswrap input {

  padding: 10px 12px 16px;

}

body .calendarfieldswrap .MuiTextField-root label.MuiInputLabel-root {
  border-color: #D5D7DA;
  border-radius: 5px !important;
  font-size: 16px !important;
  color: #181D27 !important;
  font-weight: 400 !important;
  line-height: 13px
}

body .calendarfieldswrap .MuiOutlinedInput-notchedOutline {
  border-color: #D5D7DA;
  border-width: 1px !important
}

body .calendarfieldswrap .MuiOutlinedInput-notchedOutline::placeholder {
  color: #181D27 !important
}

body .MuiPickersDay-root.Mui-selected {
  background-color: #0055C6;
}

body .calendar .MuiDrawer-paperAnchorRight.MuiDrawer-paper {
  border: 1px solid #E9EAEB;
  border-radius: 11px;
  height: auto;
  left: 50%;
  position: absolute !important;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  max-width: 730px;
}

.MuiInputBase-input::placeholder {
  color: #414651 !important
}

.custom-project-tabs .MuiTabs-scroller {
  background: #FAFAFA;
  border: 1px solid #E9EAEB;
  border-radius: 8px;
  min-height: auto;
  padding: 4px;
}


.smartdirectoryform .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
  display: none;
  padding: 0 !important
}

.smartdirectoryform .MuiFormLabel-root {
  display: none
}

.smartdirectoryform .MuiInputBase-input:not([type='file']) {
  padding: 0 !important
}

.smartdirectoryform .MuiInputBase-input[type='file'] {
  padding: 8px 2px!important
}

.white-cross-icon svg path {
  stroke: #fff
}

.smartdirectoryform .MuiMenu-paper {
  border: 1px solid #D5D7DA;
  top: 27px;
  margin-top: 2px;
  box-shadow: 0 5px 20px #d5d7dac7;
}

.smartdirectoryform .MuiMenu-paper ul li {
  padding: 7px 10px;
  margin-bottom: 5px;
  font-family: Inter !important;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 20px !important;
  border-radius: 3px
}

body .smartdirectoryform .MuiOutlinedInput-root input {
  color: #717680;
  font-family: 'Inter' !important;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 20px !important;
}

