body .centered-table-header .ag-header-cell-label .ag-header-cell-text {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

body .right-table-header .ag-header-cell-label .ag-header-cell-text {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}

body .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: 0;
}

body .ag-header-container .ag-header-cell-text {
  color: #71767f;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

body .ag-header-cell,
body .ag-header-row,
body .ag-header {
  min-height: auto !important;
}

body .ag-cell-label-container {
  padding: 0 26px;
}

.ag-header-cell-label {
  overflow: unset !important;
}

body .ag-header {
  border-bottom: 1px solid #e9eaeb !important;
  background-color: white;
}

body .ag-header-cell {
  padding: 0;
}

body .ag-ltr .ag-cell {
  padding: 10px 26px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #535862;
  font-weight: 400;
  border: 0;
  font-family: "Inter", sans-serif;
}

body .ag-row {
  border-bottom: 1px solid #e9eaeb !important;
  padding: 0 11px !important;
  display: flex;
  align-items: center;
}

body .ag-row:hover {
  background: none !important;
}

body .ag-row .ag-column-first {
  font-weight: 500;
  color: #181d27;
}

body .ag-row-hover::before {
  display: none !important;
}

body .ag-root-wrapper {
  border: 0;
  border-radius: 0px;
}

body .ag-center-cols-container .ag-row:last-child {
  border-bottom: 0 !important;
}

.ag-cell-wrapper {
  width: 100%;
}

.oddrowbg .ag-center-cols-container > .ag-row-even {
  background: #f5f5f5;
}

.oddrowbg .ag-center-cols-container > .ag-row-even:hover {
  background: #f5f5f5 !important;
}

.ag-root ::-webkit-scrollbar {
  height: 4px;
}

.ag-root ::-webkit-scrollbar-track-piece {
  background: transparent;
}

.ag-root ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 4px;
}

.actionlist .MuiMenu-paper ul li:hover p,
.actionlist .MuiMenu-paper ul li:hover svg path {
  color: #155eef;
  stroke: #155eef;
}

.actionlist .MuiMenu-paper ul li {
  transition: 0.2s all;
}

.ag-row.total-row:hover {
  background-color: #f0f0f0 !important;
}
